import { useTranslate } from '../lib/translate'
import { Dialog, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core'

const HelpDialog = ({ open, onClose }) => {
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom align="center">
          {translate('depotOverviewCard.helpTitle')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {translate('depotOverviewCard.helpText')}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default HelpDialog
