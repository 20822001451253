import { Box, Button, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CheckboxAdapter, CustomSaveButton, FormLayout, TextFieldAdapter } from '../..'
import { GOOGLE_TAG_MANAGER, ROUTES } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useIsVerified from '../../../lib/customHooks/useIsVerified'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { formatIbanForInput, parseIbanForInput, parseToUpperCase } from '../../../lib/util'
import { validateCreateStep3Form } from '../../../lib/validation'
import { fetchValidateIbanActions, storeCreatePlanValues } from '../../../redux/actions'
import {
  getBackendUserData,
  getBackendValidateIbanData,
  getFormCreatePlanPageData,
  isBackendValiadateIbanFetching,
} from '../../../redux/selectors'

export const WORKFLOW_PAGE = 'pageThree'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  inlineButton: {
    marginBottom: '1.25rem',
    marginLeft: '1rem',
  },
}))

const CreateSavingsPlanPageThree = ({
  storeValues,
  values,
  userData,
  validateIban,
  isFetching,
  ibanValidationData,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isVerified = useIsVerified()
  const pushToDataLayer = useDataLayer()
  const showSnackbar = useSnackbar()
  const accountOwner = `${userData.firstName} ${userData.lastName}`

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.PAYMENT_DATA,
    })
  }, [pushToDataLayer])

  const onSubmit = values => {
    storeValues(values)
    isVerified
      ? history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_FIVE)
      : history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_FOUR)
  }

  const handleIbanValidationResponse = ([response], state, tools) => {
    const { bic, bank, result } = response.data.validateIban ?? {}

    if (!result) {
      showSnackbar('notification.invalidIban', 'error')
      tools.changeValue(state, 'bic', () => '')
      tools.changeValue(state, 'bank', () => '')
    } else {
      tools.changeValue(state, 'bic', () => bic)
      tools.changeValue(state, 'bank', () => bank)
    }
  }

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={translate('createSavingsPlan.bankInfos')}
      pageCount={isVerified ? '3 / 4' : '3 / 5'}
      wideLayout
      showClose
      isSignedIn
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...values,
            accountOwner,
          }}
          mutators={{ handleIbanValidationResponse }}
          validate={validateCreateStep3Form}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Field
                      name="accountOwner"
                      component={TextFieldAdapter}
                      disabled
                      fullWidth
                      label={translate('formFields.accountOwner')}
                    />
                    <Box display="flex" flexDirection="row">
                      <Field
                        name="iban"
                        component={TextFieldAdapter}
                        format={formatIbanForInput}
                        parse={parseIbanForInput}
                        fullWidth
                        label={translate('formFields.iban')}
                      />
                      <CustomSaveButton
                        disabled={!values?.iban}
                        onClick={() =>
                          validateIban(values?.iban, form.mutators.handleIbanValidationResponse)
                        }
                        loading={isFetching}
                        className={classes.inlineButton}
                      >
                        {translate('actions.verify')}
                      </CustomSaveButton>
                    </Box>
                    <Field
                      name="bank"
                      component={TextFieldAdapter}
                      fullWidth
                      label={translate('formFields.bankName')}
                    />
                    <Field
                      name="bic"
                      component={TextFieldAdapter}
                      parse={parseToUpperCase}
                      fullWidth
                      label={translate('formFields.bic')}
                    />
                    <Field
                      name="directDebitAuthorization"
                      component={CheckboxAdapter}
                      label={translate('formFields.checkboxes.confirmDirectDebit')}
                    />
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton disabled={!ibanValidationData?.result}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  values: getFormCreatePlanPageData(WORKFLOW_PAGE)(state),
  userData: getBackendUserData(state),
  isFetching: isBackendValiadateIbanFetching(state),
  ibanValidationData: getBackendValidateIbanData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
  validateIban: (iban, handleIbanValidationResponse) =>
    dispatch(fetchValidateIbanActions.requestAction({ iban, handleIbanValidationResponse })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageThree)
