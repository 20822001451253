import { React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Box, Container, CardMedia, Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../config'
import useAppLogo from '../../lib/customHooks/useAppLogo'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  outerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      width: '91vw',
      margin: 'auto',
      padding: '0',
    },
  },
  innerBoxWide: {
    width: '40rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '35rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  innerBox: {
    [theme.breakpoints.up('xs')]: {
      width: '27rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  cardImage: {
    width: '10rem',
    objectFit: 'scale-down',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '8rem',
    },
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    marginLeft: 'auto',
    fontSize: 30,
    cursor: 'pointer',
  },
  appbar: props =>
    !props?.isSignedIn && {
      padding: '2rem 0',
    },
}))

const FormLayout = ({
  children,
  title,
  pageCount,
  subTitle,
  wideLayout,
  showClose,
  isSignedIn,
}) => {
  const classes = useStyles({ wideLayout, isSignedIn })
  const history = useHistory()
  const appLogo = useAppLogo()

  return (
    <Container className={classes.container} maxWidth={false}>
      <AppBar color="transparent" elevation={0} position="static" className={classes.appbar}>
        <Toolbar variant="dense">
          {!isSignedIn && (
            <CardMedia
              component="img"
              alt="App Logo"
              image={appLogo}
              className={classes.cardImage}
              onClick={() => history.push(ROUTES.DASHBOARD)}
            />
          )}
          <Box className={classes.headerTitle} mr={1} ml={3}>
            {!!title && (
              <Typography variant="h6" component="span">
                <Box component="span" fontWeight="fontWeightBold">
                  {title}
                </Box>
              </Typography>
            )}
          </Box>
          <Box>
            {!!pageCount && (
              <Typography variant="h6" component="span">
                <Box component="span" fontWeight="fontWeightBold">
                  {pageCount}
                </Box>
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box className={classes.outerBox}>
        <Box className={wideLayout ? classes.innerBoxWide : classes.innerBox}>
          <Box className={classes.subTitle}>
            {!!subTitle && (
              <Typography variant="body1" component="span">
                <Box fontWeight="fontWeightBold">{subTitle}</Box>
              </Typography>
            )}
            {showClose && (
              <CloseRoundedIcon
                className={classes.closeIcon}
                onClick={() => history.push(ROUTES.DASHBOARD)}
              />
            )}
          </Box>
          {children}
        </Box>
      </Box>
    </Container>
  )
}

export default FormLayout
