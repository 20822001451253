import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ISO_CODE_TO_CURRENCY_MAPPING } from '../../config'
import { getBackendUserData } from '../../redux/selectors'
import { formatNumber } from '../miscellaneous'
import { useTranslate } from '../translate'

const useCurrency = () => {
  const translate = useTranslate()
  const currencyISOCode = useSelector(getBackendUserData)?.currencyIsoCode

  const displayCurrency = useCallback(
    amount => {
      const currency = ISO_CODE_TO_CURRENCY_MAPPING[currencyISOCode] ?? ''

      if (amount === undefined) return currency

      return `${formatNumber(amount) ?? translate('dashboard.notAvailable')} ${currency}`
    },
    [currencyISOCode, translate]
  )

  return displayCurrency
}

export default useCurrency
