import { map } from 'lodash'
import get from 'lodash/get'
import moment from 'moment'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  createClientWithLogin,
  createUploadClientWithLogin,
  getApiBaseUrlNoGraphql,
  getClient,
  ROUTES,
  setClient,
} from '../../config'
import * as mutations from '../../config/mutations'
import * as queries from '../../config/queries'
import * as dataMappers from '../../lib/dataMapper'
import {
  appLoginSuccess,
  APP_LOGOUT,
  setExchangeRateRange,
  setIsInitialLogin,
  showNotification,
} from '../app/actions'
import {
  flushCancelPlanValues,
  flushCreatePlanValues,
  flushPlanAdjustmentValues,
  flushRequestPayoutValues,
} from '../form/actions'
import { REDUX_REHYDRATE } from '../globalActions'
import * as appActions from '../app/actions'
import * as backendActions from './actions'
import { handleGraphQLApiCall } from './sagaHelper'
import { getBackendToken, getBackendUserData, getBackendVoucherDataAllSuccess } from './selectors'

/**
 *
 * BACKEND SAGAS
 *
 */

/**
 * Handle everything after Rehydrate
 * => Get token from rehydrated store and include it in the client
 */
function* rehydrateApiLoginSaga() {
  const token = yield select(getBackendToken)
  if (token) {
    // User already signed in so we recreate our client with token
    setClient(yield createClientWithLogin(token))
  }
}

/**
 * Handle login
 */
function* fetchLoginSaga({ payload = {} }) {
  const { email, password } = payload
  const client = yield getClient(true)

  function* successCallback(response) {
    const intermediateToken = get(response, 'data.authenticate.token')
    const action = get(response, 'data.authenticate.action')
    const user = get(response, 'data.authenticate.user')
    const twoFactorAuthMode = get(response, 'data.authenticate.twoFactorAuthMode')

    if (!!intermediateToken && action) {
      yield put(backendActions.fetchLoginActions.successAction({ intermediateToken, action, user, twoFactorAuthMode }))
      setClient(yield createClientWithLogin(intermediateToken))
    } else {
      yield put(backendActions.fetchLoginActions.failureAction(response))
    }
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.AUTHENTICATE,
    variables: { email, password },
    failureAction: backendActions.fetchLoginActions.failureAction,
    successCallback: successCallback,
  })
}

/**
 * Fetch refresh token
 */
function* fetchRefreshTokenSaga() {
  const client = yield getClient(true)

  function* successCallback(response) {
    const token = get(response, 'data.refreshToken')

    if (!!token) {
      yield put(backendActions.fetchRefreshTokenActions.successAction())
      yield put(appActions.appLoginSuccess({ token }))
      setClient(yield createClientWithLogin(token))
    } else {
      yield put(backendActions.fetchRefreshTokenActions.failureAction(response))
    }
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.REFRESH_TOKEN,
    failureAction: backendActions.fetchRefreshTokenActions.failureAction,
    successCallback: successCallback,
  })
}

/**
 * Handle the 2 factor authentication
 */
function* fetchVerifySecurityCodeSaga({ payload = {} }) {
  const { code, history } = payload
  const client = yield getClient()

  function* successCallback(response) {
    const token = get(response, 'data.verifySecurityCode.token')
    yield put(appLoginSuccess({ token }))
    yield call(authenticateSuccessCallback, { token, history })
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.VERIFY_SECURITY_CODE,
    variables: { code },
    successAction: backendActions.fetchVerifyCodeActions.successAction,
    failureAction: backendActions.fetchVerifyCodeActions.failureAction,
    resultPath: 'data.verifySecurityCode.token',
    successCallback,
  })
}

/**
 * Handle resend the 2fa verify code
 */
function* fetchResendSecurityCodeSaga({ payload = {} }) {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.SEND_SECURITY_CODE,
    successAction: backendActions.fetchSendSecurityCodeActions.successAction,
    failureAction: backendActions.fetchSendSecurityCodeActions.failureAction,
    resultPath: 'data.sendSecurityCode',
    successNotification: ['notification.resendCodeSuccess', 'info'],
  })
}

/**
 * Handle everything that should be done right after successful login
 *
 */
function* authenticateSuccessCallback({ token, history }) {
  /**
   * User successfully signed in, so we want our token included
   */
  setClient(yield createClientWithLogin(token))
  yield history.replace(ROUTES.DASHBOARD)
}

/**
 * Handle logout
 * - remove token from client
 */
function* logoutSaga() {
  yield setClient(null)
}

/**
 * Handle the Start of the registering process
 *
 * Either an existing customer registers with his Name, Dob, ContractNr
 * or a new customer registers with all his personal information
 */
function* fetchStartRegisterSaga({ payload = {} }) {
  // We need to save the email in the persisted user state for display in the confirmationSentPage
  yield put(backendActions.fetchUserDataActions.successAction({ email: payload?.email }))
  const { history, countryParam, ...rest } = payload
  const client = yield getClient(true)

  const successCallback = () => {
    history.push(`${ROUTES.CONFIRMATION_SENT}?country=${countryParam}`)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.START_REGISTER_NEW,
    variables: { ...rest },
    successAction: backendActions.fetchStartRegisterActions.successAction,
    failureAction: backendActions.fetchStartRegisterActions.failureAction,
    resultPath: 'data.startRegisterNewCustomer',
    successCallback,
  })
}

/**
 * Handle the email verification
 */
function* fetchVerifyEmailSaga({ payload = {} }) {
  const { token, onSuccess } = payload
  const client = yield getClient(true)

  function* successCallback(response) {
    const intermediateToken = get(response, 'data.verifyEmail.token')
    setClient(yield createClientWithLogin(intermediateToken))
    onSuccess()
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.VERIFY_EMAIL,
    variables: { uuid: token },
    successAction: backendActions.fetchVerifyMailActions.successAction,
    failureAction: backendActions.fetchVerifyMailActions.failureAction,
    resultPath: 'data.verifyEmail',
    successNotification: 'notification.emailVerifySuccess',
    dataMapper: dataMappers.mapUserData,
    successCallback,
  })
}

/**
 * Handle resend verify mail
 */
function* fetchResendVerifyMailSaga({ payload = {} }) {
  const { email } = payload
  const client = yield getClient(true)

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.RESEND_VERIFY_MAIL,
    variables: { email },
    successAction: backendActions.fetchResendVerifyMailActions.successAction,
    failureAction: backendActions.fetchResendVerifyMailActions.failureAction,
    resultPath: 'data.resendMail',
    successNotification: ['notification.sendMailSuccess', 'info'],
  })
}

/**
 * Handle the finish registration process
 *
 * If new Customer: Has already filled out all his personal information, just phone is missing for 2FA
 * If existing Customer: Has to confirm all his personal information and set a password
 */
function* fetchFinishRegisterSaga({ payload = {} }) {
  // We need to save the phone in the persisted user state for display in the 2FA page
  const userData = yield select(getBackendUserData)
  yield put(
    backendActions.fetchUserDataActions.successAction({ ...userData, phone: payload?.phone })
  )
  const { registerNewCustomer, history, ...rest } = payload
  const client = yield getClient()
  const mutation = registerNewCustomer
    ? mutations.COMPLETE_REGISTER_NEW
    : mutations.COMPLETE_REGISTER_EXISTING

  const successCallback = () => {
    history.replace(ROUTES.SET_UP_TWO_FA)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation,
    variables: { ...rest },
    successAction: backendActions.fetchFinishRegisterActions.successAction,
    failureAction: backendActions.fetchFinishRegisterActions.failureAction,
    resultPath: registerNewCustomer
      ? 'data.completeRegisterNewCustomer'
      : 'data.completeRegisterExistingCustomer',
    successCallback,
  })
}

/**
 * Handle the phone setup verification for 2fa
 */
function* fetchVerifyPhoneSaga({ payload = {} }) {
  const { code, history, onSuccess } = payload
  const client = yield getClient()

  function* successCallback(response) {
    const token = get(response, 'data.verifyPhone.token')
    yield put(appLoginSuccess({ token }))
    yield put(backendActions.fetchUserDataActions.requestAction())
    yield put(setIsInitialLogin(true))
    yield call(authenticateSuccessCallback, { token, history })
    onSuccess()
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.VERIFY_PHONE,
    variables: { code },
    successAction: backendActions.fetchVerifyPhoneActions.successAction,
    failureAction: backendActions.fetchVerifyPhoneActions.failureAction,
    resultPath: 'data.verifyPhone.token',
    successNotification: 'notification.verifyPhoneSuccess',
    successCallback,
  })
}

/**
 * Handle the send reset password mail request
 */
function* fetchResetPasswordSaga({ payload = {} }) {
  const { email } = payload
  const client = yield getClient(true)

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.RESET_PASSWORD,
    variables: { email },
    successAction: backendActions.fetchResetPasswordActions.successAction,
    failureAction: backendActions.fetchResetPasswordActions.failureAction,
    resultPath: 'data.resetPassword',
  })
}

/**
 * Handle update password
 */
function* fetchUpdatePasswordSaga({ payload = {} }) {
  const { token, password, history } = payload
  const client = yield getClient(true)

  const successCallback = () => {
    history.push(ROUTES.LOGIN)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.UPDATE_PASSWORD,
    variables: { password, uuid: token },
    successAction: backendActions.fetchUpdatePasswordActions.successAction,
    failureAction: backendActions.fetchUpdatePasswordActions.failureAction,
    resultPath: 'data.updatePassword',
    successCallback,
    successNotification: 'notification.updatePasswordSuccess',
  })
}

/**
 * Get the user data
 */
function* fetchUserDataSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_USER_DATA,
    successAction: backendActions.fetchUserDataActions.successAction,
    failureAction: backendActions.fetchUserDataActions.failureAction,
    resultPath: 'data.user',
    dataMapper: dataMappers.mapUserData,
  })
}

/**
 * Handle file upload
 */
function* fetchUploadVerificationDocumentSaga({ payload = {} }) {
  const token = yield select(getBackendToken)
  const client = yield createUploadClientWithLogin(token)

  function* successCallback() {
    yield put(backendActions.fetchUserDataActions.requestAction())
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.UPLOAD_VERIFICATION_DOCUMENT,
    variables: payload,
    successAction: backendActions.fetchUploadVerificationDocumentActions.successAction,
    failureAction: backendActions.fetchUploadVerificationDocumentActions.failureAction,
    resultPath: 'data.uploadVerificationDocument',
    successCallback,
    successNotification: 'notification.uploadVerificationDocumentSuccess',
  })
}

/**
 * Get all files for user
 */
function* fetchCustomFilesSaga({ payload = {} }) {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_FILES,
    successAction: backendActions.fetchCustomFilesActions.successAction,
    failureAction: backendActions.fetchCustomFilesActions.failureAction,
    resultPath: 'data.customFiles',
    dataMapper: dataMappers.mapCustomFilesData,
  })
}

/**
 * Get all savings plans
 */
function* fetchSavingsPlansSaga({ payload = {} }) {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_SAVINGS_PLANS,
    successAction: backendActions.fetchSavingsPlansActions.successAction,
    failureAction: backendActions.fetchSavingsPlansActions.failureAction,
    resultPath: 'data.savingsPlans',
    dataMapper: dataMappers.mapSavingsPlansData,
  })
}

/**
 * handle savings plan creation
 */
function* fetchCreateSavingsPlanRequestSaga({ payload = {} }) {
  const { history, onSuccess, ...rest } = payload
  const client = yield getClient()

  const selectedVouchers = yield select(getBackendVoucherDataAllSuccess)

  const vouchers = map(selectedVouchers, voucher => ({
    id: voucher.data.voucher.id,
    metal: rest.metals?.[voucher.data.voucher.code.toLowerCase()],
  }))

  function* successCallback(response) {
    yield put(flushCreatePlanValues())
    yield put(backendActions.fetchVerifyVoucherActions.flushAction())
    const requestId = get(response, 'data.createSavingsPlanRequest.Id')
    yield history.push(`${ROUTES.COMPLETE_REQUEST}/${requestId}`)
    onSuccess()
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CREATE_SAVINGS_PLAN_REQUEST,
    variables: { ...rest, vouchers },
    successAction: backendActions.fetchCreateSavingsPlanRequestActions.successAction,
    failureAction: backendActions.fetchCreateSavingsPlanRequestActions.failureAction,
    resultPath: 'data.createSavingsPlanRequest',
    dataMapper: dataMappers.mapRequestData,
    successCallback,
    successNotification: 'notification.createRequestSuccess',
  })
}

/**
 * Handle the file download, here we use the js fetch() instead of apolloClient
 */
function* fetchDownloadFileSaga({ payload = {} }) {
  const { id, request, invoice, openInNewTab, name, contentDocumentVersionId } = payload
  const token = yield select(getBackendToken)

  const url = new URL(`${getApiBaseUrlNoGraphql()}/file`)
  const apiOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  id && url.searchParams.append('id', id)
  request && url.searchParams.append('request', request)
  invoice && url.searchParams.append('invoice', invoice)
  contentDocumentVersionId &&
    url.searchParams.append('contentDocumentVersionId', contentDocumentVersionId)

  try {
    const response = yield fetch(url, apiOptions)

    if (response.ok) {
      const blob = yield response.blob()
      const file = window.URL.createObjectURL(blob)

      const tempLink = document.createElement('a')
      tempLink.href = file
      openInNewTab ? (tempLink.target = '_blank') : (tempLink.download = name || 'File.pdf')
      document.body.appendChild(tempLink)
      tempLink.click()
      tempLink.remove()

      yield put(backendActions.fetchDownloadFileActions.successAction(true))
    } else {
      yield put(backendActions.fetchDownloadFileActions.failureAction(response))
    }
  } catch (e) {
    yield put(backendActions.fetchDownloadFileActions.failureAction(e))
  }
}

/**
 * Handle pdf file upload
 */
function* fetchUploadDocumentSaga({ payload = {} }) {
  const { history, request, file } = payload
  const token = yield select(getBackendToken)
  const client = yield createUploadClientWithLogin(token)

  const successCallback = () => {
    history.push(ROUTES.REQUESTS)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.UPLOAD_DOCUMENT,
    variables: { request, file },
    successAction: backendActions.fetchUploadDocumentActions.successAction,
    failureAction: backendActions.fetchUploadDocumentActions.failureAction,
    resultPath: 'data.uploadDocument',
    successCallback,
    successNotification: 'notification.uploadDocumentSuccess',
  })
}

/**
 * Send attachments via post
 */
function* fetchSendViaOtherSaga({ payload = {} }) {
  const { history, request, option } = payload
  const client = yield getClient()

  const successCallback = () => {
    history.push(ROUTES.REQUESTS)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.SEND_VIA_OTHER,
    variables: { request, option },
    successAction: backendActions.fetchSendViaOtherActions.successAction,
    failureAction: backendActions.fetchSendViaOtherActions.failureAction,
    resultPath: 'data.sendViaOther',
    successCallback,
    successNotification: 'notification.sendViaOtherSuccess',
  })
}

/**
 * Send attachments via post
 */
function* fetchPatchUserSaga({ payload = {} }) {
  const { values, onSuccess } = payload
  const client = yield getClient()

  function* successCallback() {
    yield put(backendActions.fetchUserDataActions.requestAction())
    onSuccess()
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.PATCH_USER,
    variables: values,
    successAction: backendActions.fetchPatchUserActions.successAction,
    failureAction: backendActions.fetchPatchUserActions.failureAction,
    successNotification: 'notification.patchUserSuccess',
    successCallback,
  })
}

/*
 * Fetch products (Goldbars/Coins)
 */
function* fetchProductsSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_PRODUCTS,
    successAction: backendActions.fetchProductsActions.successAction,
    failureAction: backendActions.fetchProductsActions.failureAction,
    resultPath: 'data.products',
    dataMapper: dataMappers.mapProductsData,
  })
}

/**
 * Fetch create payout request
 */
function* fetchCreatePayoutRequestSaga({ payload = {} }) {
  const { history, values } = payload
  const client = yield getClient()

  function* successCallback(response) {
    yield put(flushRequestPayoutValues())
    const requestId = get(response, 'data.createPayoutRequest.Id')
    yield history.push(`${ROUTES.COMPLETE_REQUEST}/${requestId}`)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CREATE_PAYOUT_REQUEST,
    variables: values,
    successAction: backendActions.fetchCreatePayoutRequestActions.successAction,
    failureAction: backendActions.fetchCreatePayoutRequestActions.failureAction,
    resultPath: 'data.createPayoutRequest',
    successNotification: ['notification.createRequestSuccess', 'info'],
    successCallback,
    dataMapper: dataMappers.mapRequestData,
  })
}

/**
 * Cancel Plan Request
 */
function* fetchCancelPlanRequestSaga({ payload = {} }) {
  const { history, ...values } = payload
  const client = yield getClient()

  function* successCallback(response) {
    yield put(flushCancelPlanValues())
    const requestId = get(response, 'data.cancelSavingsPlanRequest.Id')
    yield history.push(`${ROUTES.COMPLETE_REQUEST}/${requestId}`)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CANCEL_PLAN_REQUEST,
    variables: values,
    successAction: backendActions.fetchCancelPlanRequestActions.successAction,
    failureAction: backendActions.fetchCancelPlanRequestActions.failureAction,
    resultPath: 'data.cancelSavingsPlanRequest',
    successNotification: ['notification.createRequestSuccess', 'info'],
    successCallback,
    dataMapper: dataMappers.mapRequestData,
  })
}

/**
 * Adjust Plan
 */
function* fetchAdjustPlanSaga({ payload = {} }) {
  const { history, translate, ...rest } = payload
  const client = yield getClient()

  function* successCallback() {
    const message =
      rest?.newSavingsRate === 0
        ? 'notification.plantPauseSuccess'
        : 'notification.planAdjustmentSuccess'
    yield put(
      showNotification(
        translate(message, { date: moment(rest?.startMonth).format('DD.MM.YYYY') ?? '' }),
        'success'
      )
    )
    yield put(flushPlanAdjustmentValues())
    yield put(backendActions.fetchSavingPlanLogsActions.requestAction())
    history && history.push(ROUTES.DASHBOARD)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.ADJUST_PLAN,
    variables: { ...rest },
    successAction: backendActions.fetchAdjustPlanActions.successAction,
    failureAction: backendActions.fetchAdjustPlanActions.failureAction,
    resultPath: 'data.adjustSavingsPlan',
    successCallback,
    dataMapper: dataMappers.mapRequestData,
  })
}

/**
 * Fetch all requests
 */
function* fetchRequestsSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_REQUESTS,
    successAction: backendActions.fetchRequestsActions.successAction,
    failureAction: backendActions.fetchRequestsActions.failureAction,
    resultPath: 'data.requests',
    dataMapper: dataMappers.mapRequestsData,
  })
}

/**
 * Change plan name
 */
function* fetchChangePlanNameSaga({ payload = {} }) {
  const client = yield getClient()

  function* successCallback() {
    yield put(backendActions.fetchSavingsPlansActions.requestAction())
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CHANGE_PLAN_NAME,
    variables: payload,
    successAction: backendActions.fetchChangePlanNameActions.successAction,
    failureAction: backendActions.fetchChangePlanNameActions.failureAction,
    resultPath: 'data.changePlanName',
    successNotification: 'notification.changePlanNameSuccess',
    successCallback,
  })
}

/**
 * Exchange rates
 */
function* fetchExchangeRatesSaga({ payload = {} }) {
  const { range, date } = payload
  const client = yield getClient()

  yield put(setExchangeRateRange(range))

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.EXCHANGE_RATES,
    variables: { date },
    successAction: backendActions.fetchExchangeRatesActions.successAction,
    failureAction: backendActions.fetchExchangeRatesActions.failureAction,
    resultPath: 'data.exchangeRates',
    dataMapper: dataMappers.mapExchangeRatesData,
  })
}

/**
 * Change security details
 */
function* fetchChangeSecurityDetailsSaga({ payload = {} }) {
  const { history, ...rest } = payload
  const client = yield getClient()

  const successNotification = rest?.email
    ? 'notification.confirmNewMailSent'
    : rest?.phone
    ? 'notification.confirmNewPhoneSent'
    : 'notification.updatePasswordSuccess'

  const successCallback = () => {
    if (!rest?.phone) {
      history.push(ROUTES.DASHBOARD)
    }
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CHANGE_SECURITY_DETAILS,
    variables: { ...rest },
    successAction: backendActions.fetchChangeSecurityDetailsActions.successAction,
    failureAction: backendActions.fetchChangeSecurityDetailsActions.failureAction,
    resultPath: 'data.changeSecurityDetails',
    successNotification,
    successCallback,
  })
}

/**
 * Confirm new mail
 */
function* fetchConfirmNewMailSaga({ payload = {} }) {
  const { uuid } = payload
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    variables: { uuid },
    mutation: mutations.CONFIRM_NEW_MAIL,
    successAction: backendActions.fetchConfirmNewMailActions.successAction,
    failureAction: backendActions.fetchConfirmNewMailActions.failureAction,
    resultPath: 'data.confirmNewEmail',
    successNotification: 'notification.confirmNewMailSuccess',
  })
}

/**
 * Confirm new phone
 */
function* fetchConfirmNewPhoneSaga({ payload = {} }) {
  const { history, code } = payload
  const client = yield getClient()

  const successCallback = () => {
    history.push(ROUTES.DASHBOARD)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CONFIRM_NEW_PHONE,
    variables: { code },
    successAction: backendActions.fetchConfirmNewPhoneActions.successAction,
    failureAction: backendActions.fetchConfirmNewPhoneActions.failureAction,
    resultPath: 'data.confirmNewPhone',
    successNotification: 'notification.confirmNewPhoneSuccess',
    successCallback,
  })
}

/**
 * Fetch invoices
 */
function* fetchInvoicesSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_INVOICES,
    successAction: backendActions.fetchInvoicesActions.successAction,
    failureAction: backendActions.fetchInvoicesActions.failureAction,
    resultPath: 'data.invoices',
    dataMapper: dataMappers.mapInvoicesData,
  })
}

/**
 * Verify request via 2FA
 */
function* fetchVerifyRequestSaga({ payload = {} }) {
  const { requestId, code, history } = payload
  const client = yield getClient()

  const successCallback = () => {
    history.replace(ROUTES.DASHBOARD)
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.VERIFY_REQUEST_2FA,
    variables: { requestId, code },
    successAction: backendActions.fetchVerifyRequest2FAActions.successAction,
    failureAction: backendActions.fetchVerifyRequest2FAActions.failureAction,
    successNotification: 'notification.verifyRequestSuccess',
    successCallback,
  })
}

/**
 * Fetch customer messages (notifications in the appbar)
 */
function* fetchCustomerMessagesSaga({ payload = {} }) {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_CUSTOMER_MESSAGES,
    successAction: backendActions.fetchCustomerMessagesActions.successAction,
    failureAction: backendActions.fetchCustomerMessagesActions.failureAction,
    resultPath: 'data.notifications',
    dataMapper: dataMappers.mapCustomerMessagesData,
  })
}

/**
 * Fetch set customer messages
 */
function* fetchSetCustomerMessagesSaga({ payload = {} }) {
  const { notifications, newStatus } = payload
  const client = yield getClient()

  function* successCallback() {
    yield put(backendActions.fetchCustomerMessagesActions.requestAction())
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.SET_CUSTOMER_MESSAGES,
    variables: { notifications, newStatus },
    successAction: backendActions.fetchSetCustomerMessagesActions.successAction,
    failureAction: backendActions.fetchSetCustomerMessagesActions.failureAction,
    successCallback,
  })
}

/**
 * Fetch request callback saga
 */
function* fetchRequestCallbackSaga({ payload = {} }) {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.REQUEST_CALLBACK,
    successAction: backendActions.fetchRequestCallbackActions.successAction,
    failureAction: backendActions.fetchRequestCallbackActions.failureAction,
    successNotification: 'notification.requestCallbackSuccess',
  })
}

/**
 * CARE: THIS IS NOT THE SAME SF OBJECT AS ABOVE
 * The ones above are notifications from SF for the User
 * This one here creates a message from the user to the customer advisor in SF
 */
function* fetchCreateCustomerMessageSaga({ payload = {} }) {
  const { title, message } = payload
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.CREATE_CUSTOMER_MESSAGE,
    variables: { title, message },
    successAction: backendActions.fetchCreateCustomerMessageActions.successAction,
    failureAction: backendActions.fetchCreateCustomerMessageActions.failureAction,
    successNotification: 'notification.createCustomerMessageSuccess',
  })
}

function* fetchCountryDepartmentSaga({ payload = {} }) {
  const { id } = payload
  const client = yield getClient(true)

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_COUNTRY_DEPARTMENT,
    variables: { id },
    successAction: backendActions.fetchCountryDepartmentActions.successAction,
    failureAction: backendActions.fetchCountryDepartmentActions.failureAction,
    resultPath: 'data.countryDepartment',
    dataMapper: dataMappers.mapCountryDepartmentData,
  })
}

function* fetchCountryDepartmentByCountryCodeSaga({ payload = {} }) {
  const { countryCode } = payload
  const client = yield getClient(true)

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_COUNTRY_DEPARTMENT_BY_COUNTRY_CODE,
    variables: { countryCode },
    successAction: backendActions.fetchCountryDepartmentByCountryCodeActions.successAction,
    failureAction: backendActions.fetchCountryDepartmentByCountryCodeActions.failureAction,
    resultPath: 'data.countryDepartmentByCountryCode',
    dataMapper: dataMappers.mapCountryDepartmentData,
  })
}

function* fetchDeleteCacheSaga({ payload = {} }) {
  const { history, newRoute } = payload
  const client = yield getClient()

  const successCallback = () => {
    if (newRoute) {
      history.push(newRoute)
      history.go(0)
    } else {
      history.go(0)
    }
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.DELETE_CACHE,
    successAction: backendActions.fetchDeleteCacheActions.successAction,
    failureAction: backendActions.fetchDeleteCacheActions.failureAction,
    successCallback,
  })
}

function* fetchVerifyVoucherSaga({ payload = {} }) {
  const { code, gold, silber, platin, palladium, screen } = payload
  const client = yield getClient()

  function successAction(mappedResult) {
    return backendActions.fetchVerifyVoucherActions.successAction({
      ...mappedResult,
      id: code.toLowerCase(),
    })
  }

  function failureAction(response) {
    return backendActions.fetchVerifyVoucherActions.failureAction({
      ...response,
      id: code.toLowerCase(),
    })
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.VERIFY_VOUCHER,
    variables: { code, gold, silber, platin, palladium, screen },
    successAction,
    failureAction,
    resultPath: 'data.verifyVoucherCode',
    dataMapper: dataMappers.mapVoucherData,
    successNotification: 'notification.verifyVoucherSuccess',
  })
}

function* fetchMetalsSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_METALS,
    successAction: backendActions.fetchMetalsActions.successAction,
    failureAction: backendActions.fetchMetalsActions.failureAction,
    resultPath: 'data.metals',
    dataMapper: dataMappers.mapMetalsData,
  })
}

function* fetchDeleteRequestSaga({ payload = {} }) {
  const { id } = payload
  const client = yield getClient()

  function* successCallback() {
    yield put(backendActions.fetchRequestsActions.requestAction())
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.DELETE_REQUEST,
    variables: { id },
    successAction: backendActions.fetchDeleteRequestActions.successAction,
    failureAction: backendActions.fetchDeleteRequestActions.failureAction,
    resultPath: 'data.deleteRequest',
    successNotification: 'notification.deleteRequestSuccess',
    successCallback,
  })
}

function* fetchGooglePlacesApiSaga({ payload = {} }) {
  const { input, placeId } = payload
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_GOOGLE_PLACES,
    variables: { input, placeId },
    successAction: backendActions.fetchGooglePlacesApiActions.successAction,
    failureAction: backendActions.fetchGooglePlacesApiActions.failureAction,
    resultPath: 'data.googlePlaces',
  })
}

function* fetchMigrateSaga({ payload = {} }) {
  const {
    values: { uuid },
    newRoute,
    history,
  } = payload
  const client = yield getClient()

  function* successCallback(response) {
    yield put(backendActions.fetchDeleteCacheActions.requestAction({ history, newRoute }))
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.MIGRATE,
    variables: { uuid },
    successAction: backendActions.fetchMigrateActions.successAction,
    failureAction: backendActions.fetchMigrateActions.failureAction,
    resultPath: 'data.migrate',
    successNotification: 'notification.migrationSuccess',
    successCallback,
  })
}

function* fetchValidateIbanSaga({ payload = {} }) {
  const { iban, handleIbanValidationResponse } = payload
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_VALIDATE_IBAN,
    variables: { iban },
    successAction: backendActions.fetchValidateIbanActions.successAction,
    failureAction: backendActions.fetchValidateIbanActions.failureAction,
    resultPath: 'data.validateIban',
    successCallback: handleIbanValidationResponse,
  })
}

function* fetchAccountBrandSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_ACCOUNT_BRAND,
    successAction: backendActions.fetchAccountBrandActions.successAction,
    failureAction: backendActions.fetchAccountBrandActions.failureAction,
    resultPath: 'data.accountBrand',
    dataMapper: dataMappers.mapAccountBrandData,
  })
}

function* fetchSavingPlanLogsSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_SAVING_PLAN_LOGS,
    successAction: backendActions.fetchSavingPlanLogsActions.successAction,
    failureAction: backendActions.fetchSavingPlanLogsActions.failureAction,
    resultPath: 'data.savingPlanLogs',
    dataMapper: dataMappers.mapSavingPlanLogData,
  })
}

function* fetchPerformancesSaga() {
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.query,
    query: queries.GET_PERFORMANCES,
    successAction: backendActions.fetchPerformancesActions.successAction,
    failureAction: backendActions.fetchPerformancesActions.failureAction,
    resultPath: 'data.performances',
  })
}

function* fetchDeleteAdjustmentSaga({ payload = {} }) {
  const { savingPlanLogId } = payload
  const client = yield getClient()

  function* successCallback() {
    yield put(backendActions.fetchSavingPlanLogsActions.requestAction())
  }

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.DELETE_ADJUSTMENT,
    variables: { savingPlanLogId },
    successAction: backendActions.fetchDeleteAdjustmentActions.successAction,
    failureAction: backendActions.fetchDeleteAdjustmentActions.failureAction,
    resultPath: 'data.deleteAdjustment',
    successNotification: 'notification.deleteAdjustmentSuccess',
    successCallback,
  })
}

function* fetchApplyVoucherSaga({ payload = {} }) {
  const { savingsPlanId, voucherId } = payload
  const client = yield getClient()

  yield handleGraphQLApiCall({
    apiMethod: client.mutate,
    mutation: mutations.APPLY_VOUCHER,
    variables: { savingsPlanId, voucherId },
    successAction: backendActions.fetchApplyVoucherActions.successAction,
    failureAction: backendActions.fetchApplyVoucherActions.failureAction,
    resultPath: 'data.applyVoucher',
    successNotification: 'notification.applyVoucherSuccess',
  })
}

const backendSagas = () => [
  takeLatest(backendActions.fetchLoginActions.requestType, fetchLoginSaga),
  takeLatest(backendActions.fetchRefreshTokenActions.requestType, fetchRefreshTokenSaga),
  takeLatest(APP_LOGOUT, logoutSaga),
  takeLatest(REDUX_REHYDRATE, rehydrateApiLoginSaga),
  takeLatest(backendActions.fetchStartRegisterActions.requestType, fetchStartRegisterSaga),
  takeLatest(backendActions.fetchVerifyMailActions.requestType, fetchVerifyEmailSaga),
  takeLatest(backendActions.fetchResendVerifyMailActions.requestType, fetchResendVerifyMailSaga),
  takeLatest(backendActions.fetchFinishRegisterActions.requestType, fetchFinishRegisterSaga),
  takeLatest(backendActions.fetchSendSecurityCodeActions.requestType, fetchResendSecurityCodeSaga),
  takeLatest(backendActions.fetchVerifyPhoneActions.requestType, fetchVerifyPhoneSaga),
  takeLatest(backendActions.fetchVerifyCodeActions.requestType, fetchVerifySecurityCodeSaga),
  takeLatest(backendActions.fetchResetPasswordActions.requestType, fetchResetPasswordSaga),
  takeLatest(backendActions.fetchUpdatePasswordActions.requestType, fetchUpdatePasswordSaga),
  takeLatest(backendActions.fetchUserDataActions.requestType, fetchUserDataSaga),
  takeEvery(
    backendActions.fetchUploadVerificationDocumentActions.requestType,
    fetchUploadVerificationDocumentSaga
  ),
  takeLatest(backendActions.fetchCustomFilesActions.requestType, fetchCustomFilesSaga),
  takeLatest(backendActions.fetchSavingsPlansActions.requestType, fetchSavingsPlansSaga),
  takeLatest(
    backendActions.fetchCreateSavingsPlanRequestActions.requestType,
    fetchCreateSavingsPlanRequestSaga
  ),
  takeLatest(backendActions.fetchDownloadFileActions.requestType, fetchDownloadFileSaga),
  takeLatest(backendActions.fetchUploadDocumentActions.requestType, fetchUploadDocumentSaga),
  takeLatest(backendActions.fetchSendViaOtherActions.requestType, fetchSendViaOtherSaga),
  takeLatest(backendActions.fetchPatchUserActions.requestType, fetchPatchUserSaga),
  takeLatest(backendActions.fetchProductsActions.requestType, fetchProductsSaga),
  takeLatest(
    backendActions.fetchCreatePayoutRequestActions.requestType,
    fetchCreatePayoutRequestSaga
  ),
  takeLatest(backendActions.fetchCancelPlanRequestActions.requestType, fetchCancelPlanRequestSaga),
  takeLatest(backendActions.fetchAdjustPlanActions.requestType, fetchAdjustPlanSaga),
  takeLatest(backendActions.fetchRequestsActions.requestType, fetchRequestsSaga),
  takeLatest(backendActions.fetchChangePlanNameActions.requestType, fetchChangePlanNameSaga),
  takeLatest(backendActions.fetchExchangeRatesActions.requestType, fetchExchangeRatesSaga),
  takeLatest(
    backendActions.fetchChangeSecurityDetailsActions.requestType,
    fetchChangeSecurityDetailsSaga
  ),
  takeLatest(backendActions.fetchConfirmNewMailActions.requestType, fetchConfirmNewMailSaga),
  takeLatest(backendActions.fetchConfirmNewPhoneActions.requestType, fetchConfirmNewPhoneSaga),
  takeLatest(backendActions.fetchInvoicesActions.requestType, fetchInvoicesSaga),
  takeLatest(backendActions.fetchVerifyRequest2FAActions.requestType, fetchVerifyRequestSaga),
  takeLatest(backendActions.fetchCustomerMessagesActions.requestType, fetchCustomerMessagesSaga),
  takeLatest(
    backendActions.fetchSetCustomerMessagesActions.requestType,
    fetchSetCustomerMessagesSaga
  ),
  takeLatest(backendActions.fetchRequestCallbackActions.requestType, fetchRequestCallbackSaga),
  takeLatest(
    backendActions.fetchCreateCustomerMessageActions.requestType,
    fetchCreateCustomerMessageSaga
  ),
  takeLatest(backendActions.fetchCountryDepartmentActions.requestType, fetchCountryDepartmentSaga),
  takeLatest(backendActions.fetchCountryDepartmentByCountryCodeActions.requestType, fetchCountryDepartmentByCountryCodeSaga),
  takeLatest(backendActions.fetchDeleteCacheActions.requestType, fetchDeleteCacheSaga),
  takeLatest(backendActions.fetchVerifyVoucherActions.requestType, fetchVerifyVoucherSaga),
  takeLatest(backendActions.fetchMetalsActions.requestType, fetchMetalsSaga),
  takeLatest(backendActions.fetchDeleteRequestActions.requestType, fetchDeleteRequestSaga),
  takeLatest(backendActions.fetchGooglePlacesApiActions.requestType, fetchGooglePlacesApiSaga),
  takeLatest(backendActions.fetchMigrateActions.requestType, fetchMigrateSaga),
  takeLatest(backendActions.fetchValidateIbanActions.requestType, fetchValidateIbanSaga),
  takeLatest(backendActions.fetchAccountBrandActions.requestType, fetchAccountBrandSaga),
  takeLatest(backendActions.fetchSavingPlanLogsActions.requestType, fetchSavingPlanLogsSaga),
  takeLatest(backendActions.fetchPerformancesActions.requestType, fetchPerformancesSaga),
  takeLatest(backendActions.fetchDeleteAdjustmentActions.requestType, fetchDeleteAdjustmentSaga),
  takeLatest(backendActions.fetchApplyVoucherActions.requestType, fetchApplyVoucherSaga),
]

export default backendSagas
