import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Card,
  FormGroup,
  CardContent,
  Button,
  Typography,
  MenuItem,
  Link,
} from '@material-ui/core'
import get from 'lodash/get'

import { useTranslate } from '../../lib/translate'
import {
  PasswordSafetyIndicator,
  TextFieldAdapter,
  CheckboxAdapter,
  SelectAdapter,
  CustomSaveButton,
} from './../'
import { COUNTRY_DEPARTMENT, ROUTES, SALUTATION_OPTIONS, TITLE_OPTIONS } from '../../config'
import {
  required,
  validatePassword,
  validateConfirmPassword,
  validateEmail,
  validateSameEmail,
} from '../../lib/validation'
import { isBackendStartRegisterFetching } from '../../redux/selectors'
import useQueryParams from '../../lib/customHooks/useQueryParams'

const useStyles = makeStyles(theme => ({
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  bottomContents: {
    margin: `${theme.spacing(3.75)}px auto`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  passwordHint: {
    color: theme.palette.text.hint,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const salutationOptions = Object.values(SALUTATION_OPTIONS)
const titleOptions = Object.values(TITLE_OPTIONS)

const RegisterWithoutAccountForm = ({ onSubmit, isFetching }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const queryParams = useQueryParams()

  const countryParam = queryParams.get('country')
  const isAT = countryParam
    ? countryParam.toUpperCase() === COUNTRY_DEPARTMENT.AUSTRIA.value
    : false

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateConfirmPassword}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent className={classes.cardContentPadding}>
              <Box>
                <Field
                  name="salutation"
                  component={SelectAdapter}
                  label={translate('formFields.salutation')}
                  fullWidth
                >
                  {salutationOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {translate(option.key)}
                    </MenuItem>
                  ))}
                </Field>
                {isAT && (
                  <Field
                    name="title"
                    component={SelectAdapter}
                    label={translate('formFields.title')}
                    fullWidth
                  >
                    {titleOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {translate(option.key)}
                      </MenuItem>
                    ))}
                  </Field>
                )}
                <Field
                  name="firstName"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.firstName')}
                />
                <Field
                  name="lastName"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.lastName')}
                />
                <Field
                  name="email"
                  component={TextFieldAdapter}
                  validate={validateEmail}
                  fullWidth
                  autoComplete="username"
                  label={translate('formFields.mail')}
                />
                <Field
                  name="email2"
                  component={TextFieldAdapter}
                  validate={validateSameEmail(values.email)}
                  fullWidth
                  label={translate('formFields.mail2')}
                />
                <Field
                  name="password"
                  component={TextFieldAdapter}
                  validate={validatePassword}
                  fullWidth
                  type="password"
                  autoComplete="new-password"
                  label={translate('formFields.pass')}
                />
                <Field
                  name="confirmPassword"
                  component={TextFieldAdapter}
                  fullWidth
                  type="password"
                  autoComplete="new-password"
                  label={translate('formFields.confirmPassword')}
                />
              </Box>
              <Box className={classes.passwordSafetyContainer}>
                <Typography variant="subtitle2">{translate('register.security')}</Typography>
                <PasswordSafetyIndicator password={get(values, 'password')} />
              </Box>
              <Box>
                <Typography variant="subtitle2" paragraph className={classes.passwordHint}>
                  {translate('register.passwordHint')}
                </Typography>
              </Box>
              <Box>
                <FormGroup>
                  <Field
                    name="tos"
                    component={CheckboxAdapter}
                    validate={required}
                    label={
                      <Typography variant="subtitle2">
                        {`${translate('formFields.checkboxes.preTos')} `}
                        <Link
                          href={`${ROUTES.TOS}${countryParam ? `?country=${countryParam}` : ''}`}
                          target="_blank"
                        >
                          {translate('formFields.checkboxes.tos')}
                        </Link>
                        {` ${translate('formFields.checkboxes.midTos')} `}
                        <Link
                          href={`${ROUTES.PRIVACY_POLICY}${
                            countryParam ? `?country=${countryParam}` : ''
                          } `}
                          target="_blank"
                        >
                          {translate('formFields.checkboxes.privacyPolicy')}
                        </Link>
                        {` ${translate('formFields.checkboxes.postTos')}`}
                      </Typography>
                    }
                  />
                  {/* REMOVED FOR NOW, TODO: ADD AGAIN AS SOON AS REQUESTED
                  <Field
                    name="newsletter"
                    component={CheckboxAdapter}
                    label={
                      <Typography variant="subtitle2">
                        {translate('formFields.checkboxes.newsletter')}
                      </Typography>
                    }
                  /> */}
                </FormGroup>
              </Box>
            </CardContent>
          </Card>
          <Box className={classes.bottomContents}>
            <Button variant="text" onClick={() => history.goBack()}>
              {translate('actions.back')}
            </Button>
            <CustomSaveButton loading={isFetching}>
              {translate('actions.register')}
            </CustomSaveButton>
          </Box>
        </form>
      )}
    />
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendStartRegisterFetching(state),
})

export default connect(mapStateToProps)(RegisterWithoutAccountForm)
