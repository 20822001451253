import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { CURRENCY, GOOGLE_TAG_MANAGER } from '../../config'
import { getBackendCountryDepartmentData, getFormCreatePlanData } from '../../redux/selectors'
import { useTranslate } from '../translate'

const useDataLayer = () => {
  const translate = useTranslate()

  const countryCode = useSelector(getBackendCountryDepartmentData)?.countryCode
  const metalFormData = useSelector(getFormCreatePlanData)?.pageOne

  return useCallback(
    (objectToPush, metalArgs) => {
      if (process.env.NODE_ENV !== 'production') return

      window.dataLayer = window.dataLayer || []

      if (!window.dataLayer.some(e => e.country) && countryCode) {
        window.dataLayer.splice(1, 0, { country: countryCode })
      }

      const savingsPlanData = {
        funnelVariant: 'Login',
        funnelCompleted: 0,
        transactionProduct: null,
        transactionValue: null,
        transactionCurrency: null,
      }
      const metalData = metalArgs || metalFormData
      const isSavingsPlanStep = objectToPush.event === GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP

      if (isSavingsPlanStep) {
        if (metalData) {
          Object.assign(
            savingsPlanData,
            Object.entries(metalData).reduce(
              (result, [key, value]) => {
                if (value === true) {
                  return {
                    ...result,
                    transactionProduct:
                      result.transactionProduct +
                      `${result.transactionProduct ? ',' : ''}${translate(`metals.${key}`)}`,
                  }
                } else if (key.includes('Monthly')) {
                  return { ...result, transactionValue: (result.transactionValue += +value) }
                } else {
                  return result
                }
              },
              { transactionProduct: '', transactionValue: 0 }
            )
          )
        }
        if (savingsPlanData.transactionValue) {
          savingsPlanData.transactionCurrency = CURRENCY.EUR
        }
        if (objectToPush.funnelStep === GOOGLE_TAG_MANAGER.FUNNEL_STEP.COMPLETION) {
          delete savingsPlanData.funnelVariant
          savingsPlanData.funnelCompleted = 1
        }
      }

      window.dataLayer.push({ ...objectToPush, ...(isSavingsPlanStep ? savingsPlanData : {}) })
    },
    [countryCode, metalFormData, translate]
  )
}

export default useDataLayer
