import { Avatar, Card, CardActionArea, CardHeader, makeStyles, Typography } from '@material-ui/core'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import { useField } from 'react-final-form'

import { METAL_ICON, METAL_TYPE } from '../../config'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: ({ isSelected }) => (isSelected ? '0 0 3px #86754F' : ''),
    '&:hover': {
      boxShadow: '0 0 3px #86754F',
    },
    transition: 'unset',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const MetalCardController = ({ metalName }) => {
  const translate = useTranslate()
  const {
    input: { onChange, value },
  } = useField(metalName)
  const classes = useStyles({ isSelected: value })

  const icon = METAL_ICON?.[metalName] ?? METAL_ICON[METAL_TYPE.GOLD]
  const title = translate(`metals.${metalName}`)

  return (
    <Card className={classes.container}>
      <CardActionArea onClick={() => onChange(!value)} disableRipple>
        <CardHeader
          avatar={<Avatar alt="asset" src={icon} />}
          title={
            <Typography className={classes.text}>
              {title} {value && <CheckCircleOutlinedIcon color="secondary" />}
            </Typography>
          }
          disableTypography
        />
      </CardActionArea>
    </Card>
  )
}

export default MetalCardController
