import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { createBackendReducer } from '../../createReducer'
import * as actions from '../actions'

/**
 * List of reducers for our queries
 *
 * Add here an { key: createBackendReducer(actions.backendAction) } pair for each query
 */
const queryReducerList = {
  user: createBackendReducer(actions.fetchUserDataActions),
  customFiles: createBackendReducer(actions.fetchCustomFilesActions),
  plans: createBackendReducer(actions.fetchSavingsPlansActions),
  products: createBackendReducer(actions.fetchProductsActions),
  requests: createBackendReducer(actions.fetchRequestsActions),
  exchangeRates: createBackendReducer(actions.fetchExchangeRatesActions),
  invoices: createBackendReducer(actions.fetchInvoicesActions),
  customerMessages: createBackendReducer(actions.fetchCustomerMessagesActions),
  countryDepartment: createBackendReducer(actions.fetchCountryDepartmentActions),
  countryDepartmentByCountryCode: createBackendReducer(actions.fetchCountryDepartmentByCountryCodeActions),
  metals: createBackendReducer(actions.fetchMetalsActions),
  googlePlaces: createBackendReducer(actions.fetchGooglePlacesApiActions),
  validateIban: createBackendReducer(actions.fetchValidateIbanActions),
  accountBrand: createBackendReducer(actions.fetchAccountBrandActions),
  savingPlanLogs: createBackendReducer(actions.fetchSavingPlanLogsActions),
  performances: createBackendReducer(actions.fetchPerformancesActions),
}

/**
 * Persist config
 * Add the keys here to persist the data
 */
const persistConfig = {
  key: 'query',
  storage,
  whitelist: ['user'],
}

// combine all mutation reducers
const combinedReducers = combineReducers(queryReducerList)

// persist the combined reducer
const queryReducer = persistReducer(persistConfig, combinedReducers)

export default queryReducer
