import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CustomSaveButton, DepositOverviewCard, FormLayout, ProductCard } from '../..'
import { ROUTES } from '../../../config'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { reduceProducts } from '../../../lib/util'
import { fetchProductsActions, storeRequestPayoutValues } from '../../../redux/actions'
import {
  getBackendProductsData,
  getFormRequestPayoutPageData,
  getRemainingGram,
} from '../../../redux/selectors'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './RequestPayoutPage1'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_3 } from './RequestPayoutPage3'

export const WORKFLOW_PAGE = 'pageFour'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const RequestPayoutPageFour = ({
  values,
  storeValues,
  planId,
  fetchProducts,
  products,
  page3Data,
  remainingGram,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()

  const onSubmit = values => {
    if (remainingGram < 0) {
      showSnackbar('notification.cannotPayoutMoreThanAvailable', 'error')
    } else {
      const cleanedValues = Object.entries(values).reduce(
        (obj, [k, v]) => (v ? ((obj[k] = v), obj) : obj),
        {}
      )
      if (isEmpty(cleanedValues)) {
        showSnackbar('notification.atLeastOneProduct')
      } else {
        storeValues(cleanedValues)
        history.push(ROUTES.REQUEST_PAYOUT_STEP_FIVE)
      }
    }
  }

  useEffect(() => {
    if (isEmpty(products)) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let reducedProducts = {}
  if (products) {
    reducedProducts = reduceProducts(products)
  }

  return (
    <FormLayout title={translate('payoutRequest.shipment')} wideLayout showClose isSignedIn>
      <Box mt={5} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={values}
          mutators={{
            removeItem: ([fieldName], state, utils) => {
              utils.changeValue(state, fieldName, () => null)
            },
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Box className={classes.cardContentPadding} mb={2}>
                  <Typography variant="h6" paragraph className={classes.boldText}>
                    {translate('cancelPlans.requestPhysicalTitle')}
                  </Typography>
                  <Typography variant="h6" paragraph>
                    {translate('cancelPlans.deliverySubjectToAvailability')}
                  </Typography>
                </Box>
              </Box>
              {!isEmpty(reducedProducts) &&
                Object.entries(reducedProducts).map(([key, val]) => (
                  <ProductCard
                    key={key}
                    items={val}
                    type={key}
                    title={translate(`payoutRequest.products.${key}`)}
                    formValues={values}
                    formMutators={form.mutators}
                  />
                ))}
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
              </Box>
              <DepositOverviewCard
                planId={planId}
                formValues={{ ...page3Data, ...values }}
                payout
              />
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const page1Data = getFormRequestPayoutPageData(WORKFLOW_PAGE_1)(state)

  return {
    values: getFormRequestPayoutPageData(WORKFLOW_PAGE)(state),
    planId: get(page1Data, 'savingsPlanId'),
    products: getBackendProductsData(state),
    page3Data: getFormRequestPayoutPageData(WORKFLOW_PAGE_3)(state),
    remainingGram: getRemainingGram(state),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeRequestPayoutValues(WORKFLOW_PAGE)(values)),
  fetchProducts: () => dispatch(fetchProductsActions.requestAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageFour)
