import isEmpty from 'lodash/isEmpty'
import { connect, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import {
  CreateSavingsPlanPageFive,
  CreateSavingsPlanPageFour,
  CreateSavingsPlanPageOne,
  CreateSavingsPlanPageThree,
  CreateSavingsPlanPageTwo,
  CustomRoute,
} from '../..'
import { ROUTES } from '../../../config'
import { getBackendUserData, getFormCreatePlanData } from '../../../redux/selectors'

/**
 * Create Plan Navigation
 *
 * Our nested navigation component for the create savingsplan workflow
 */
const CreatePlanNavigation = ({ isFormDataMissing }) => {
  const { pathname } = useLocation()
  const hasAddress = !!useSelector(getBackendUserData)?.street

  if (ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE !== pathname && isFormDataMissing) {
    return <Redirect to={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE} />
  }

  if (!hasAddress) {
    return <Redirect push={false} to={ROUTES.COMPLETE_PROFILE} />
  }

  return (
    <Switch>
      <CustomRoute path={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE}>
        <CreateSavingsPlanPageOne />
      </CustomRoute>
      <CustomRoute path={ROUTES.CREATE_SAVINGS_PLAN_STEP_TWO}>
        <CreateSavingsPlanPageTwo />
      </CustomRoute>
      <CustomRoute path={ROUTES.CREATE_SAVINGS_PLAN_STEP_THREE}>
        <CreateSavingsPlanPageThree />
      </CustomRoute>
      <CustomRoute path={ROUTES.CREATE_SAVINGS_PLAN_STEP_FOUR}>
        <CreateSavingsPlanPageFour />
      </CustomRoute>
      <CustomRoute path={ROUTES.CREATE_SAVINGS_PLAN_STEP_FIVE}>
        <CreateSavingsPlanPageFive />
      </CustomRoute>
    </Switch>
  )
}

const mapStateToProps = state => ({
  isFormDataMissing: isEmpty(getFormCreatePlanData(state)),
})

export default connect(mapStateToProps)(CreatePlanNavigation)
