import get from 'lodash/get'

const userDataMapping = {
  Name: 'customerId',
  Id: 'id',
  Birthday__c: 'dateOfBirth',
  Email__c: 'email',
  Title__c: 'title',
  Customer__c: 'customer',
  Lastname__c: 'lastName',
  Firstname__c: 'firstName',
  Salutation__c: 'salutation',
  Mobile_Phone__c: 'phone',
  Initial_created__c: 'newCustomer',
  City__c: 'city',
  Nationality__c: 'nationalityCode',
  Nationality_Text__c: 'nationality',
  State__c: 'state',
  Street__c: 'street',
  Country_Code_F__c: 'countryCode',
  Country__c: 'country',
  Postal_Code__c: 'postalCode',
  Email_User_Account__c: 'emailUserAccount',
  WF_Verification_File__c: 'verificationFile',
  Verification_File__c: 'newVerificationFileFieldNotUsed',
  Is_Verified__c: 'isVerified',
  Email_Validation_Time__c: 'emailValidationTime',
  Reset_Password_Timestamp__c: 'resetPasswordTimestamp',
  X2FA_Validation_Timestamp__c: 'X2FA_validationTimestamp',
  Reset_Password_Validation_Timestamp__c: 'resetPasswordValidationTimestamp',
  Country_Department__c: 'countryDepartment',
  Interested_in_Palladium__c: 'requestedPalladium',
  Interested_in_Platin__c: 'requestedPlatinum',
  Customer_Number_F__c: 'customerNumber',
  Adresszusatz__c: 'addressAddition',
  Address_Addition_Door__c: 'addressAdditionDoor',
  Customer_Group__c: 'customerGroup',
  Created_by_the_clerk__c: 'createdByClerk',
  CurrencyIsoCode: 'currencyIsoCode',
  PrimaryColor__c: 'primaryColor',
  SecondaryColor__c: 'secondaryColor',
  PartnerId__c: 'partnerId',
  PartnerLogo__c: 'partnerLogo',
  PartnerMail__c: 'partnerMail',
  PartnerName__c: 'partnerName',
  PartnerCity__c: 'partnerCity',
  PartnerStreet__c: 'partnerStreet',
  PartnerPhone__c: 'partnerPhone',
  FinishedMigration__c: 'finishedMigration',
  Contact__c: 'contact',
  Underage__c: 'isUnderaged',
  Status__c: 'status',
  EMSPMinimumRateGoldF__c: 'minRateGold',
  EMSPMinimumRateSilverF__c: 'minRateSilver',
  EMSPMinimumRatePlatinF__c: 'minRatePlatinum',
  EMSPMinimumRatePalladiumF__c: 'minRatePalladium',
  EMSPEntryFeeGoldF__c: 'entryFeeGold',
  EMSPEntryFeeSilverF__c: 'entryFeeSilver',
  EMSPEntryFeePlatinF__c: 'entryFeePlatinum',
  EMSPEntryFeePalladiumF__c: 'entryFeePalladium',
}

const customFileMapping = {
  Id: 'id',
  Name: 'name',
  User_Account__c: 'userAccount',
  User_Account_2__c: 'userAccount2',
  Verification_Date_of_Expiry__c: 'verificationExpirationDate',
  Verification_FormularType__c: 'verificationFormularType',
  Verification_Number__c: 'verificationNumber',
  CreatedDate: 'date',
  Pdf_Created__c: 'pdfCreated',
  Savings_plan__c: 'savingsPlan',
  Request__c: 'request',
  Verification_Issuing_Authority__c: 'issuingAuthority',
}

const requestsMapping = {
  Id: 'id',
  Postal_Request__c: 'postalRequest',
  Status__c: 'status',
  User_Account__c: 'userAccount',
  Request_Number__c: 'requestNumber',
  Gold_Savings_Plan__c: 'isGoldPlan',
  Savings_Rate_Gold__c: 'savingsRateGold',
  Start_Savings_Plan_Gold__c: 'savingsPlanGoldStart',
  Silver_Savings_Plan__c: 'isSilverPlan',
  Savings_Rate_Silver__c: 'savingsRateSilver',
  Start_Savings_Plan_Silver__c: 'savingsPlanSilverStart',
  Platinum_Savings_Plan__c: 'isPlatinumPlan',
  Palladium_Savings_Plan__c: 'isPalladiumPlan',
  BIC__c: 'bic',
  IBAN__c: 'iban',
  Bank__c: 'bank',
  Allowed_Debit_Authorization__c: 'allowedDebitAuthorization',
  Contractor_1__c: 'contractor1',
  Contractor_2__c: 'contractor2',
  Direct_Debit_Authorization__c: 'debitAuthorization',
  Rate_In_Total__c: 'total',
  RecordTypeId: 'recordTypeId',
  Referenced_Saving_Plan__c: 'savingsPlan',
  Cancellation_Date__c: 'cancellationDate',
  Name: 'incrementingId',
  Starting_Date__c: 'startingDate',
  No_Politically_Exposed_Person__c: 'noPoliticallyExpose',
  CreatedDate: 'createdDate',
}

const savingsPlanMapping = {
  Id: 'id',
  Allowed_Debit_Authorization__c: 'allowedDebitAuthorization',
  Associated_Country__c: 'associatedCountry',
  Bank__c: 'bank',
  BIC__c: 'bic',
  Completion_Date__c: 'completionDate',
  Contractor_1__c: 'contractor1',
  Contractor_2__c: 'contractor2',
  TotalPerformance__c: 'totalPerformance',
  Current_Depot_Value__c: 'currentValue',
  Current_Total_Gram_New__c: 'gram',
  Deposit_Amount__c: 'depositAmount',
  Gold_Savings_Plan__c: 'isGoldPlan',
  IBAN__c: 'iban',
  Metal__c: 'metal',
  Recommendation__c: 'recommendation',
  Name: 'name',
  Savings_Rate__c: 'savingsRate',
  Silver_Savings_Plan__c: 'isSilverPlan',
  Start_Savings_Plan__c: 'savingsPlanStart',
  Termination_Date__c: 'terminationDate',
  User_Account__c: 'userAccount',
  Customer_Saving_Plan_Name__c: 'customName',
  Status__c: 'status',
  Sonderzahlungsreferenz__c: 'specialPaymentReference',
  AvailableAmountQuantity__c: 'availableGram',
  Available_Amount_Euro__c: 'availableEuro',
  CurrentDepotValuewithoutMarge__c: 'depotValueWithoutMarge',
  CreatedDate: 'createdDate',
  Metal_Text__c: 'metalName',
  User_Account_1_F__c: 'userAccount1',
  User_Account_2_F__c: 'userAccount2',
  Platinum_Savings_Plan__c: 'isPlatinumPlan',
  Palladium_Savings_Plan__c: 'isPalladiumPlan',
  pausedMonth__c: 'pausedMonth',
  EMSPConditionMinRateF__c: 'minRate',
}

const contractorMapping = {
  Id: 'id',
  Salutation: 'salutation',
  Title__pc: 'title',
  FirstName: 'firstName',
  LastName: 'lastName',
  PersonEmail: 'email',
  PersonBirthdate: 'birthdate',
  Phone: 'phone',
  Contract_Number__c: 'contractNumber',
  Related_Created_by_Contractor_1__pc: 'contractor1',
}

const exchangeRateMapping = {
  Id: 'id',
  Name: 'name',
  Metal__c: 'metal',
  CreatedDate: 'date',
  Price_Gram__c: 'gramPrice',
  PriceGramInCHF__c: 'gramPriceCHF',
  Exchange_rate_Ounce__c: 'exchangeRateOunce',
  Exchange_rate_update__c: 'exchangeRateUpdate',
  Exchange_Rate_Date__c: 'exchangeRateDate',
  CustomerBuyMargin__c: 'customerBuyMargin',
  CustomerBuyMarginInCHF__c: 'customerBuyMarginCHF',
  ExchangeRateOunceInCHF__c: 'exchangeRateOunceCHF',
  Metal_Name__c: 'metalName',
}

const productsMapping = {
  Id: 'id',
  Name: 'name',
  Country_Department__c: 'countryDepartment',
  Certificate__c: 'certificate',
  Description: 'description',
  Diameter_In_mm__c: 'diameterMM',
  Fineness__c: 'fineness',
  Gross_Weight_In_Gramm__c: 'grossWeight',
  Manufacturer__c: 'manufacturer',
  Origin_Country__c: 'originCountry',
  Product_Information__c: 'information',
  Thickness_In_mm__c: 'thicknessMM',
  Weight_In_Oz__c: 'weightInOz',
  RecordTypeId: 'recordTypeId',
  Embossing_Costs__c: 'embossingCosts',
  ProduktLink_1__c: 'productLink1',
  ProduktLink_2__c: 'productLink2',
  ProduktLink_3__c: 'productLink3',
}

const transactionsMapping = {
  Id: 'id',
  Amount__c: 'amount',
  CreatedDate: 'createdDate',
  Intended_Use__c: 'intendedUse',
  Reference_Number__c: 'referenceNumber',
  Savings_plan__c: 'savingsPlan',
  Savings_Plan_Coverage__c: 'savingsPlanCoverage',
  Transaction_Status__c: 'status',
  User_Account_1_F__c: 'userAccount',
}

const invoicesMapping = {
  Id: 'id',
  BruttoRollup__c: 'brutto',
  Status__c: 'status',
  Due_date__c: 'dueDate',
  Description__c: 'description',
  Invoice_date__c: 'invoiceDate',
  Savings_Plan__c: 'savingsPlan',
  User_Account_1_F__c: 'userAccount1',
  User_Account_2_F__c: 'userAccount2',
  Zahlungsart__c: 'type',
  Total_Gramm__c: 'gram',
  ExchangeRatePriceBuy__c: 'buyPrice',
  TradePrice__c: 'price',
  InvoicePerformance__c: 'performance',
}

const depotPerformanceMapping = {
  Id: 'id',
  Name: 'name',
  CreatedById: 'createdBy',
  X24h_Change__c: 'x24hChange',
  User_Account__c: 'userAccount',
  Depot_Total_Change__c: 'totalChange',
  Depot_Total_Deposit__c: 'totalDeposit',
  Depot_Total_Amount_Gold__c: 'totalAmountGold',
  Depot_Total_Amount_Silver__c: 'totalAmountSilver',
  Depot_Total_Amount_Palladium__c: 'totalAmountPalladium',
  Depot_Total_Amount_Platinum__c: 'totalAmountPlatinum',
  CreatedDate: 'createdDate',
}

const planPerformanceMapping = {
  Id: 'id',
  Name: 'name',
  User__c: 'user',
  CreatedDate: 'createdDate',
  Savings_Plan__c: 'savingsPlan',
  User_Account__c: 'userAccount',
  X24h_Change__c: 'x24hChange',
  Total_Change__c: 'totalChange',
  User_Account_1_F__c: 'UserAccount1',
  User_Account_2_F__c: 'UserAccount2',
}

const customerMessagesMapping = {
  Id: 'id',
  Name: 'title',
  Status__c: 'status',
  Message_Text__c: 'text',
  User_Account__c: 'userAccount',
  CreatedDate: 'createdDate',
  Invoice__c: 'invoiceId',
  ContentDokumentVersionID__c: 'contentDocumentVersionId',
}

const countryDepartmentMapping = {
  Id: 'id',
  Bank_1__c: 'bank1',
  Bank_2__c: 'bank2',
  Bic_1__c: 'bic1',
  Bic_2__c: 'bic2',
  CEO__c: 'ceo',
  Country_Code__c: 'countryCode',
  Email__c: 'email',
  EMSP_Email__c: 'emspEmail',
  Iban_1__c: 'iban1',
  Iban_2__c: 'iban2',
  Identification_Number__c: 'idNumber',
  Jurisdiction__c: 'jurisdiction',
  Name__c: 'name',
  Orgwide_Mail_ID__c: 'orgwideMail',
  Phone__c: 'phone',
  Postal_Code_and_City__c: 'postalCodeCity',
  Shipping_costs_URL__c: 'shippingCostsURL',
  Street_and_Number__c: 'streetHouseNr',
  Website__c: 'website',
  Impressum__c: 'impressum',
  BGB__c: 'BGBFileId',
  BGB_Distribution_Public_URL__c: 'BGBFileUrl',
  PEP__c: 'PEPFileId',
  Preisliste__c: 'priceListFileId',
  Preisliste_Distribution_Public_URL__c: 'priceListFileUrl',
  Privacy_File__c: 'privacyFileId',
  MaintenanceMode__c: 'showMaintenance',
  faqURL__c: 'faqUrl',
}

const voucherMapping = {
  Id: 'id',
  Name: 'code',
  End_Date__c: 'endDate',
  Start_Date__c: 'startDate',
  Gutschein_Name__c: 'name',
  Country_Department__c: 'countryDepartment',
  Percentage_discount__c: 'percentageDiscount',
  Customer_Text__c: 'text',
  AddedGramGold__c: 'gramGold',
  AddedGramPalladium__c: 'gramPalladium',
  AddedGramPlatin__c: 'gramPlatinum',
  AddedGramSilver__c: 'gramSilver',
  Credit_Euro_Gold__c: 'euroGold',
  Credit_Euro_Palladium__c: 'euroPalladium',
  Credit_Euro_Platinum__c: 'euroPlatinum',
  Credit_Euro_Silver__c: 'euroSilver',
}

const metalMapping = {
  Id: 'id',
  Name: 'name',
  Min_Rate_Privat__c: 'minRatePrivate',
  Min_Rate_Dist__c: 'minRateSales',
}

const accountBrandMapping = {
  Id: 'id',
  Name: 'name',
  AccountId: 'accountId',
  CompanyName: 'companyName',
  LogoUrl: 'logoUrl',
  PrimaryColor__c: 'primaryColor',
  SecondaryColor__c: 'secondaryColor',
  Website: 'website',
}

const savingPlanLogMapping = {
  Id: 'id',
  Status__c: 'status',
  SavingPlan__c: 'savingPlanId',
  ChangeDate__c: 'changeDate',
  NewSavingPlanRate__c: 'newSavingPlanRate',
}

/**
 * Data mapping function for backend responses that return an array of objects
 * - Takes an object with the key mappings
 * - Takes the array of backend objects
 */
const mapBackendObjectsArray = mappingObject => array =>
  array.map(obj =>
    Object.entries(get(obj, 'content', {})).reduce(
      // eslint-disable-next-line no-sequences
      (out, [key, val]) => ((out[mappingObject[key] || key] = val), out),
      {}
    )
  )

/**
 * Same as above just for plain object
 */
const mapBackendObject = mappingObject => obj =>
  Object.entries(obj).reduce(
    // eslint-disable-next-line no-sequences
    (out, [key, val]) => ((out[mappingObject[key] || key] = val), out),
    {}
  )

/**
 * Same as above, but data is nested in 'content'
 * (Should always be like that for single item responses)
 */
const mapBackendObjectWithContent = mappingObject => obj =>
  Object.entries(get(obj, 'content', {})).reduce(
    // eslint-disable-next-line no-sequences
    (out, [key, val]) => ((out[mappingObject[key] || key] = val), out),
    {}
  )

export const mapUserData = user => mapBackendObject(userDataMapping)(user)

export const mapCustomFilesData = fileArray => mapBackendObjectsArray(customFileMapping)(fileArray)

export const mapSavingsPlansData = planArray =>
  mapBackendObjectsArray(savingsPlanMapping)(planArray)

export const mapRequestData = request => mapBackendObject(requestsMapping)(request)

export const mapRequestsData = requestArray => mapBackendObjectsArray(requestsMapping)(requestArray)

export const mapContractorData = contractor => mapBackendObject(contractorMapping)(contractor)

export const mapExchangeRatesData = ratesArray =>
  mapBackendObjectsArray(exchangeRateMapping)(ratesArray)

export const mapProductsData = productsArray =>
  mapBackendObjectsArray(productsMapping)(productsArray)

export const mapTransactionsData = transactionsArray =>
  mapBackendObjectsArray(transactionsMapping)(transactionsArray)

export const mapInvoicesData = invoicesArray =>
  mapBackendObjectsArray(invoicesMapping)(invoicesArray)

export const mapDepotPerformanceData = depotPerformance =>
  mapBackendObjectWithContent(depotPerformanceMapping)(depotPerformance)

export const mapPlanPerformanceData = planPerformance =>
  mapBackendObjectWithContent(planPerformanceMapping)(planPerformance)

export const mapCustomerMessagesData = msgArray =>
  mapBackendObjectsArray(customerMessagesMapping)(msgArray)

export const mapCountryDepartmentData = dep =>
  mapBackendObjectWithContent(countryDepartmentMapping)(dep)

export const mapVoucherData = ({ metals, voucher }) => ({
  metals,
  voucher: mapBackendObjectWithContent(voucherMapping)(voucher),
})

export const mapMetalsData = metalArray => mapBackendObjectsArray(metalMapping)(metalArray)

export const mapAccountBrandData = brand => mapBackendObjectWithContent(accountBrandMapping)(brand)

export const mapSavingPlanLogData = savingPlanLog =>
  mapBackendObjectsArray(savingPlanLogMapping)(savingPlanLog)
