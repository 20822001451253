import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
  useTheme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { CustomSaveButton, FormLayout } from '../..'
import { METAL_TYPE, ROUTES } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import useDialog from '../../../lib/customHooks/useDialog'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { required } from '../../../lib/validation'
import { storeRequestPayoutValues } from '../../../redux/actions'
import { getBackendSavingsPlansData, getFormRequestPayoutPageData } from '../../../redux/selectors'
import { RadioAdapter } from '../../common/FormFields'
import InfoTextField from '../../common/InfoTextField'
import HelpDialog from '../../HelpDialog'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './RequestPayoutPage1'

export const WORKFLOW_PAGE = 'pageTwo'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inlineButton: {
    marginBottom: '1.25rem',
    marginLeft: '1rem',
  },
}))

const PAYOUT_OPTIONS = {
  FIAT: 'fiatWithdrawal',
  PHYSICAL: 'physicalDelivery',
}

const RequestPayoutPageTwo = ({ formValues, plan, storeValues }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const history = useHistory()
  const theme = useTheme()
  const showSnackbar = useSnackbar()
  const [dialogState, { openDialog, closeDialog }] = useDialog()

  const onSubmit = ({ payoutOption, ...values }) => {
    if (!plan?.availableEuro) {
      showSnackbar('notification.nothingAvailableForPayout')
    } else {
      storeValues({
        ...values,
        physicalDelivery: payoutOption === PAYOUT_OPTIONS.PHYSICAL,
        fiatWithdrawal: payoutOption === PAYOUT_OPTIONS.FIAT,
        payoutOption,
      })
      history.push(
        payoutOption === PAYOUT_OPTIONS.FIAT
          ? ROUTES.REQUEST_PAYOUT_STEP_THREE
          : ROUTES.REQUEST_PAYOUT_STEP_FOUR
      )
    }
  }

  return (
    <FormLayout title={translate('payoutRequest.title')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={formValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3} ml={5}>
                <Box display={'flex'} justifyContent="end">
                  <IconButton
                    size="small"
                    color="secondary"
                    className={classes.helpIconButton}
                    onClick={openDialog}
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Typography variant="h6" paragraph className={classes.boldText}>
                  {translate('payoutRequest.totalDepotAmount')}
                </Typography>
                <Typography variant="h4" paragraph color="primary" className={classes.boldText}>
                  {displayCurrency(plan?.currentValue)}
                </Typography>
                <Box display="flex">
                  <InfoTextField
                    label={translate('payoutRequest.availableAmount')}
                    value={displayCurrency(plan?.availableEuro)}
                    InputProps={{
                      style: {
                        color:
                          plan?.availableEuro && plan?.availableEuro > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      },
                    }}
                  />
                  <InfoTextField
                    label={translate('payoutRequest.blockedAmount')}
                    value={displayCurrency(
                      get(plan, 'currentValue', 0) - get(plan, 'availableEuro', 0)
                    )}
                  />
                </Box>
                <Typography variant="h6" paragraph className={classes.boldText}>
                  {translate('payoutRequest.totalGrammAmount')}
                </Typography>
                <Typography variant="h4" paragraph color="primary" className={classes.boldText}>
                  {`${plan?.gram} g`}
                </Typography>
                <Box display="flex">
                  <InfoTextField
                    label={translate('payoutRequest.availableAmountShipping')}
                    value={`${plan?.availableGram} g`}
                    InputProps={{
                      style: {
                        color:
                          plan?.availableGram && plan?.availableGram > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      },
                    }}
                  />
                  <InfoTextField
                    label={translate('payoutRequest.blockedAmount')}
                    value={`${(
                      Math.floor((get(plan, 'gram', 0) - get(plan, 'availableGram', 0)) * 10000) /
                      10000
                    ).toFixed(4)} g`}
                  />
                </Box>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('payoutRequest.selectPayoutType')}
                    </Typography>
                    <Field name="payoutOption" component={RadioAdapter} validate={required}>
                      <FormControlLabel
                        value={PAYOUT_OPTIONS.FIAT}
                        control={<Radio />}
                        label={translate('formFields.fiatWithdrawal')}
                      />
                      {METAL_TYPE.GOLD === plan?.metalName?.toLowerCase() && (
                        <Box mt={2}>
                          <FormControlLabel
                            value={PAYOUT_OPTIONS.PHYSICAL}
                            control={<Radio />}
                            label={translate('cancelPlans.metalDeliveryText')}
                          />
                        </Box>
                      )}
                    </Field>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton disabled={!values?.payoutOption}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
      <HelpDialog open={dialogState} onClose={closeDialog} />
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const savingsPlanId = get(getFormRequestPayoutPageData(WORKFLOW_PAGE_1)(state), 'savingsPlanId')
  return {
    formValues: getFormRequestPayoutPageData(WORKFLOW_PAGE)(state),
    plan: find(getBackendSavingsPlansData(state), { id: savingsPlanId }),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeRequestPayoutValues(WORKFLOW_PAGE)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageTwo)
