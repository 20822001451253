import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import ImageIcon from '@material-ui/icons/Image'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CustomSaveButton, FormLayout, SelectAdapter, TextFieldAdapter } from '../..'
import { GOOGLE_TAG_MANAGER, ROUTES, VERIFICATION_DOCUMENT_TYPE } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useDialog from '../../../lib/customHooks/useDialog'
import useIsVerified from '../../../lib/customHooks/useIsVerified'
import { usePrevious } from '../../../lib/miscellaneous'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { required, validateDateToBeAfterDate, validateFileUpload } from '../../../lib/validation'
import {
  fetchUploadVerificationDocumentActions,
  storeCreatePlanValues,
} from '../../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendUploadVerificationDocumentData,
  isBackendUploadVerificationDocumentFetching,
} from '../../../redux/selectors'
import { DatePickerAdapter } from '../../common/FormFields'
import InfoDialog from '../../common/InfoDialog'

export const WORKFLOW_PAGE = 'pageFour'

const verificationDocOptions = Object.values(VERIFICATION_DOCUMENT_TYPE)
const DE_AT_DocOptions = verificationDocOptions.slice(0, 2)

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  uploadBox: {
    border: '1px dashed #B88F00',
    borderRadius: '8px',
    height: '5.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonHeight: {
    height: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  underlinedText: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const UploadButton = ({ name, inputId }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const showSnackbar = useSnackbar()

  const handleAddFile = (
    input,
    {
      target: {
        validity,
        files: [file],
      },
    }
  ) => {
    if (validity.valid) {
      const size = file?.size ?? 0
      if ((size / 1024 / 1024).toFixed(4) <= 5) {
        input.onChange({ file })
      } else {
        showSnackbar('notification.fileSizeLimit')
      }
    } else {
      showSnackbar('notification.fileInvalid')
    }
  }

  return (
    <Box mb={4}>
      <label htmlFor={inputId} className={classes.uploadBox}>
        <Button variant="text" component="span" fullWidth className={classes.buttonHeight}>
          {translate('actions.upload')}
        </Button>
      </label>
      <Box mt={2}>
        <Field name={name} validate={validateFileUpload}>
          {({ input, meta }) => (
            <>
              <input
                accept="image/*, application/pdf"
                id={inputId}
                type="file"
                hidden
                onChange={event => handleAddFile(input, event)}
              />
              {meta.error && meta.touched && (
                <Typography component="span" color="error">
                  {translate(meta.error)}
                </Typography>
              )}
            </>
          )}
        </Field>
      </Box>
    </Box>
  )
}

const UploadButtonWithPreview = ({ values, removeFile, backside }) => {
  const translate = useTranslate()

  const uploadDocFieldName = `uploadDocument${backside ? '2' : ''}`
  const inputId = `uploadInput${backside ? '2' : ''}`

  return (
    <>
      <Collapse
        in={get(values, 'verificationDocument') !== VERIFICATION_DOCUMENT_TYPE.PASSPORT.value}
        unmountOnExit
      >
        {backside ? (
          <Typography variant="body1" gutterBottom>
            {`${translate('createSavingsPlan.backside')}:`}
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            {`${translate('createSavingsPlan.frontside')}:`}
          </Typography>
        )}
      </Collapse>
      <Collapse in={!values[uploadDocFieldName]} unmountOnExit>
        <UploadButton name={uploadDocFieldName} inputId={inputId} />
      </Collapse>
      <Collapse in={!!values[uploadDocFieldName]} unmountOnExit>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          key={values[uploadDocFieldName]?.file.name}
          id="fileBox"
        >
          <Button variant="text" size="large" startIcon={<ImageIcon />} disableRipple>
            {values[uploadDocFieldName]?.file.name}
          </Button>
          <IconButton aria-label="delete" onClick={() => removeFile(uploadDocFieldName)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Collapse>
    </>
  )
}

const SkippedUploadInstructions = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const countryDepartment = useSelector(getBackendCountryDepartmentData)
  const { streetHouseNr, postalCodeCity } = countryDepartment || {}

  return (
    <>
      <Typography
        paragraph
        dangerouslySetInnerHTML={{
          __html: translate('createSavingsPlan.skippedDocumentUploadInstructions1'),
        }}
      ></Typography>
      <Typography paragraph>
        {`1) ${translate('createSavingsPlan.skippedDocumentUploadInstructions2')} `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${translate('createSavingsPlan.skippedDocumentUploadInstructions3')}`}
        >
          {translate('createSavingsPlan.skippedDocumentUploadInstructions3')}
        </a>
      </Typography>
      <Typography paragraph>
        {translate('createSavingsPlan.skippedDocumentUploadInstructions4')}
      </Typography>
      <Typography paragraph>
        {`2) ${translate('createSavingsPlan.skippedDocumentUploadInstructions5')}`}
      </Typography>
      <Typography className={classes.bold}>{translate('company.name')}</Typography>
      <Typography className={classes.bold}>{streetHouseNr}</Typography>
      <Typography className={classes.bold}>{postalCodeCity}</Typography>
    </>
  )
}

const UploadCardGroup = ({ values, removeFile }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [showDialogCHIdentityCard, { closeDialog: closeDialoCHIdentityCard }] = useDialog()

  const docOptions = DE_AT_DocOptions
  const verificationDocumentType = get(values, 'verificationDocument')
  const idLabel =
    verificationDocumentType === VERIFICATION_DOCUMENT_TYPE.PASSPORT.value
      ? translate('formFields.passportNumber')
      : translate('formFields.identificationNumber')

  return (
    <>
      <Box mb={4}>
        <Card>
          <CardContent className={classes.cardContentPadding}>
            <Typography variant="body1" paragraph>
              {translate('createSavingsPlan.documentTypeC1')}
            </Typography>
            <Field
              name="verificationDocument"
              component={SelectAdapter}
              validate={required}
              fullWidth
              label={translate('formFields.verificationDocument')}
            >
              {docOptions.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {translate(option.key)}
                </MenuItem>
              ))}
            </Field>
            <Collapse in={!!verificationDocumentType} unmountOnExit>
              <Typography variant="body1" paragraph>
                {translate('createSavingsPlan.yourData')}
              </Typography>
              <Field
                name="identificationNumber"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={idLabel}
              />
              <Field
                name="issuingAuthority"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={translate('formFields.issuingAuthority')}
              />
              <Field
                name="validUntil"
                component={DatePickerAdapter}
                validate={validateDateToBeAfterDate(moment())}
                disablePast
                fullWidth
                label={translate('formFields.validUntil')}
              />
            </Collapse>
          </CardContent>
        </Card>
      </Box>
      <Box mb={3}>
        <Collapse in={!!verificationDocumentType} unmountOnExit>
          <Card>
            <CardContent className={classes.cardContentPadding}>
              <Typography variant="body1">
                {translate('createSavingsPlan.uploadArea', {
                  docType:
                    verificationDocumentType &&
                    translate(
                      find(VERIFICATION_DOCUMENT_TYPE, {
                        value: verificationDocumentType,
                      })?.key
                    ),
                })}
              </Typography>
              <Collapse in={!values.skippedDocumentUpload} unmountOnExit>
                <Typography variant="caption" color="textSecondary">
                  {translate('createSavingsPlan.recommendedFileTypes')}
                </Typography>
                <Typography variant="caption" color="textSecondary" paragraph>
                  {translate('createSavingsPlan.maxUploadSize')}
                </Typography>
                <UploadButtonWithPreview values={values} removeFile={removeFile} />
                <Collapse
                  in={verificationDocumentType !== VERIFICATION_DOCUMENT_TYPE.PASSPORT.value}
                  unmountOnExit
                >
                  <UploadButtonWithPreview values={values} removeFile={removeFile} backside />
                </Collapse>
              </Collapse>
              {values.skippedDocumentUpload && <SkippedUploadInstructions />}
            </CardContent>
          </Card>
        </Collapse>
      </Box>
      <InfoDialog
        open={showDialogCHIdentityCard}
        onClose={closeDialoCHIdentityCard}
        title={translate('createSavingsPlan.identityCardInfo.title')}
        infoText={
          <>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: translate('createSavingsPlan.identityCardInfo.text'),
              }}
            />
            <ol>
              <li>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: translate('createSavingsPlan.identityCardInfo.list0'),
                  }}
                />
              </li>
              <li>
                <Typography variant="body1">
                  {translate('createSavingsPlan.identityCardInfo.list1')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {translate('createSavingsPlan.identityCardInfo.list2')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {translate('createSavingsPlan.identityCardInfo.list3')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {translate('createSavingsPlan.identityCardInfo.list4')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {translate('createSavingsPlan.identityCardInfo.list5')}
                </Typography>
              </li>
            </ol>
          </>
        }
      />
    </>
  )
}

const CreateSavingsPlanPageFour = ({ storeValues, values, uploadFile, isFetching, data }) => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const isVerified = useIsVerified()
  const pushToDataLayer = useDataLayer()
  const wasPreviouslyFetching = usePrevious(isFetching)

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.PROOF_OF_IDENTITY,
    })
  }, [pushToDataLayer])
  const onSubmit = values => {
    storeValues(values)

    if (!isVerified) {
      uploadFile({
        file: !values.skippedDocumentUpload ? values.uploadDocument.file : null,
        file2: values.uploadDocument2?.file || null,
        type: values.verificationDocument,
        number: values.identificationNumber,
        expDate: moment(values.validUntil).format('YYYY-MM-DD'),
        authority: values.issuingAuthority,
        skippedUpload: !!values.skippedDocumentUpload,
      })
    }
  }

  useEffect(() => {
    if (!isFetching && wasPreviouslyFetching && !!data) {
      if (!isVerified) {
        history.replace(ROUTES.CREATE_SAVINGS_PLAN_STEP_FIVE)
      } else {
        history.replace(ROUTES.CREATE_SAVINGS_PLAN_STEP_FIVE)
      }
    }
  }, [isFetching, wasPreviouslyFetching, history, isVerified, data])

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={`${translate('createSavingsPlan.legitimation')}`}
      pageCount="4 / 5"
      wideLayout
      showClose
      isSignedIn
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={values}
          mutators={{
            removeFile: ([fieldName], state, utils) => {
              utils.changeValue(state, fieldName, () => null)
            },
          }}
          render={({ form, handleSubmit, values, hasSubmitErrors }) => (
            <form onSubmit={handleSubmit}>
              <UploadCardGroup values={values} removeFile={form.mutators.removeFile} />
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching} disabled={hasSubmitErrors}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendUploadVerificationDocumentFetching(state),
  data: getBackendUploadVerificationDocumentData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
  uploadFile: args => {
    dispatch(fetchUploadVerificationDocumentActions.requestAction(args))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageFour)
