import React, { useEffect, useState } from 'react'
import { Container, makeStyles, Paper } from '@material-ui/core'
import { getBackendCountryDepartmentByCountryCodeData } from '../../../redux/selectors'
import { fetchCountryDepartmentByCountryCodeActions } from '../../../redux/actions'
import { connect } from 'react-redux'

import { COUNTRY_DEPARTMENT } from '../../../config'
import useQueryParams from '../../../lib/customHooks/useQueryParams'

const useStyles = makeStyles(theme => ({
  pdf: {
    height: '100vh',
    width: '100%',
    border: 'none',
  },
  container: {
    padding: 0,
    overflow: 'hidden',
  },
  paper: {
    height: '100vh',
  },
}))

const PrivacyPolicyPage = ({ fetchCountryDepartment, countryDepartment }) => {
  const classes = useStyles()
  const queryParams = useQueryParams()
  const [isLoading, setIsLoading] = useState(true)

  const countryParam = queryParams.get('country')

  const countryCode =
    countryParam &&
    Object.values(COUNTRY_DEPARTMENT).some(it => it.tld === countryParam.toLowerCase())
      ? countryParam.toUpperCase()
      : 'AT'

  useEffect(() => {
    fetchCountryDepartment({ countryCode: countryCode })
    setIsLoading(false)
  }, [fetchCountryDepartment, countryCode])

  useEffect(() => {
    console.log(countryDepartment)
  }, [countryDepartment])

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper className={classes.paper}>
        {!isLoading && (
          <iframe title="BGB" src={countryDepartment?.BGBFileUrl} className={classes.pdf}></iframe>
        )}
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => ({
  countryDepartment: getBackendCountryDepartmentByCountryCodeData(state),
})

const mapDispatchToProps = {
  fetchCountryDepartment: fetchCountryDepartmentByCountryCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPage)
