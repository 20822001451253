import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AddressAutocompleteAdapter,
  CodeInput,
  CustomSaveButton,
  DatePickerAdapter,
  FormLayout,
  NationalitiesAdapter,
  SelectAdapter,
  TextFieldAdapter,
} from '..'
import {
  COUNTRY_DEPARTMENT,
  GOOGLE_TAG_MANAGER,
  ROUTES,
  SALUTATION_OPTIONS,
  TITLE_OPTIONS,
} from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import { usePrevious } from '../../lib/miscellaneous'
import { sanitizeUserDataForForm } from '../../lib/sanitizeData'
import { useTranslate } from '../../lib/translate'
import { required, validateDate } from '../../lib/validation'
import { fetchPatchUserActions, fetchSendSecurityCodeActions } from '../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendSendCodeData,
  getBackendUserData,
  getBackendUserError,
  isBackendPatchUserFetching,
  isBackendSendCodeFetching,
  isBackendUserFetching,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bottomContents: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoText: {
    color: theme.palette.text.hint,
  },
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
}))

const salutationOptions = Object.values(SALUTATION_OPTIONS)
const titleOptions = Object.values(TITLE_OPTIONS)

const _ConfirmUserDataForm = ({
  onSubmit,
  userData,
  isFetching,
  codeValues,
  setCodeValues,
  isFetchingSendCode,
  showCodeInput,
  onBackClick,
  isUpdate,
  onEnterPress,
  isFetchingPatchUser,
  countryDepartment,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const isAT = countryDepartment?.countryCode
    ? countryDepartment.countryCode.toUpperCase() === COUNTRY_DEPARTMENT.AUSTRIA.value
    : false

  return (
    <Form
      initialValues={sanitizeUserDataForForm(userData)}
      onSubmit={onSubmit}
      mutators={{
        autofillAddress: ([addressObj], state, utils) => {
          if (addressObj) {
            Object.entries(addressObj).forEach(([key, val]) => {
              if (!val) {
                return
              }
              utils.changeValue(state, key, () => val)
            })
          }
        },
      }}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Collapse in={!showCodeInput}>
            {!isUpdate && (
              <Typography variant="subtitle2" paragraph>
                {translate('finishRegistration.subtitle')}
              </Typography>
            )}
            <Card className={classes.card}>
              <CardContent className={classes.cardContentPadding}>
                <Typography variant="subtitle2">
                  {isUpdate
                    ? translate('userProfile.updateInfo', {
                        salutation: translate(
                          find(salutationOptions, { value: values.salutation })?.key || ''
                        ),
                        name: get(values, 'lastName', ''),
                      })
                    : translate('finishRegistration.completeProfile', {
                        salutation: translate(
                          find(salutationOptions, { value: values.salutation })?.key || ''
                        ),
                        name: get(values, 'lastName', ''),
                      })}
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContentPadding}>
                <Field
                  name="salutation"
                  component={SelectAdapter}
                  label={translate('formFields.salutation')}
                  fullWidth
                  disabled={!!userData?.salutation}
                >
                  {salutationOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {translate(option.key)}
                    </MenuItem>
                  ))}
                </Field>
                {isAT && (
                  <Field
                    name="title"
                    component={SelectAdapter}
                    label={translate('formFields.title')}
                    fullWidth
                    disabled={!!userData?.title}
                  >
                    {titleOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {translate(option.key)}
                      </MenuItem>
                    ))}
                  </Field>
                )}
                <Field
                  name="firstName"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.firstName')}
                  disabled={!!userData?.firstName}
                />
                <Field
                  name="lastName"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.lastName')}
                  disabled={!!userData?.lastName}
                />
                <Field
                  name="dateOfBirth"
                  component={DatePickerAdapter}
                  disableFuture
                  openTo="year"
                  validate={validateDate}
                  fullWidth
                  label={translate('formFields.dateOfBirth')}
                  disabled={!!userData?.dateOfBirth}
                />
                <Field
                  name="nationality"
                  component={NationalitiesAdapter}
                  validate={required}
                  disabled={!!userData?.nationality}
                />
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContentPadding}>
                <Field
                  name="street"
                  validate={required}
                  formMutator={form.mutators.autofillAddress}
                  component={AddressAutocompleteAdapter}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Field
                      name="addressAddition"
                      component={TextFieldAdapter}
                      placeholder={translate('formFields.addressAdditionPlaceholder')}
                      fullWidth
                      label={translate('formFields.addressAddition')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="addressAdditionDoor"
                      component={TextFieldAdapter}
                      fullWidth
                      label={translate('formFields.addressAdditionDoor')}
                    />
                  </Grid>
                </Grid>
                <Field
                  name="postalCode"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.postCode')}
                />
                <Field
                  name="city"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.city')}
                />
                <Field
                  name="state"
                  component={TextFieldAdapter}
                  fullWidth
                  label={translate('formFields.state')}
                />
                <Field
                  name="country"
                  component={NationalitiesAdapter}
                  isCountryPicker
                  validate={required}
                  fullWidth
                  label={translate('formFields.country')}
                />
              </CardContent>
            </Card>
          </Collapse>
          <Collapse in={showCodeInput} unmountOnExit>
            <Box mt="2rem" mb="2rem">
              <CodeInput values={codeValues} setValues={setCodeValues} onSubmit={onEnterPress} />
            </Box>
          </Collapse>
          <Box className={classes.bottomContents}>
            <Button onClick={onBackClick}>{translate('actions.back')}</Button>
            <CustomSaveButton loading={isFetching || isFetchingSendCode || isFetchingPatchUser}>
              {translate('finishRegistration.continue')}
            </CustomSaveButton>
          </Box>
        </form>
      )}
    />
  )
}

const mapStateToPropsUserDataForm = state => ({
  userData: getBackendUserData(state),
  isFetching: isBackendUserFetching(state),
  isFetchingSendCode: isBackendSendCodeFetching(state),
  isFetchingPatchUser: isBackendPatchUserFetching(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const ConfirmUserDataForm = connect(mapStateToPropsUserDataForm)(_ConfirmUserDataForm)

const CompleteProfile = ({
  patchUser,
  sendCode,
  isFetching,
  user,
  isUpdate,
  isFetchingSendCode,
  sendCodeSuccess,
  error,
  onEndUpdate,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const wasPreviouslyFetchingUser = usePrevious(isFetching)
  const wasPreviouslyFetchingCode = usePrevious(isFetchingSendCode)
  const pushToDataLayer = useDataLayer()

  const [codeValues, setCodeValues] = useState({})
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [submitValues, setSubmitValues] = useState()

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.REGISTRATION,
    })
  }, [pushToDataLayer])

  const onSubmit = ({ nationality, country, dateOfBirth, ...values }) => {
    if (!showCodeInput) {
      sendCode()
      setSubmitValues({
        ...values,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
        nationality: nationality.code,
        country: country.code,
      })
    } else {
      patchUser({
        values: {
          ...submitValues,
          securityCode: Object.values(codeValues).join(''),
        },
      })
    }
  }
  const onEnterPress = () => {
    patchUser({
      values: {
        ...submitValues,
        securityCode: Object.values(codeValues).join(''),
      },
    })
  }

  const handleBackClick = () => {
    if (showCodeInput) {
      setShowCodeInput(false)
      setCodeValues({})
    } else {
      if (isUpdate) {
        onEndUpdate()
      } else {
        history.goBack()
      }
    }
  }

  useEffect(() => {
    if (!isFetchingSendCode && wasPreviouslyFetchingCode && sendCodeSuccess) {
      setShowCodeInput(true)
    }
  }, [isFetchingSendCode, pushToDataLayer, sendCodeSuccess, wasPreviouslyFetchingCode])

  useEffect(() => {
    if (!isFetching && wasPreviouslyFetchingUser && !isEmpty(user) && !error) {
      isUpdate ? onEndUpdate() : history.replace(ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE)
    }
  }, [
    isFetching,
    wasPreviouslyFetchingUser,
    user,
    history,
    isUpdate,
    error,
    onEndUpdate,
    pushToDataLayer,
  ])

  if (isUpdate) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('userProfile.updateProfileTitle')}
        </Typography>
        <ConfirmUserDataForm
          onSubmit={onSubmit}
          isFetching={isFetching}
          codeValues={codeValues}
          setCodeValues={setCodeValues}
          showCodeInput={showCodeInput}
          onBackClick={handleBackClick}
          onEnterPress={onEnterPress}
          isUpdate={isUpdate}
        />
      </Container>
    )
  }

  return (
    <FormLayout showClose isSignedIn>
      <Typography variant="h6" gutterBottom className={classes.textBold}>
        {translate('finishRegistration.title')}
      </Typography>
      <ConfirmUserDataForm
        onSubmit={onSubmit}
        isFetching={isFetching}
        codeValues={codeValues}
        setCodeValues={setCodeValues}
        showCodeInput={showCodeInput}
        onBackClick={handleBackClick}
        onEnterPress={onEnterPress}
        isUpdate={isUpdate}
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendUserFetching(state),
  user: getBackendUserData(state),
  isFetchingSendCode: isBackendSendCodeFetching(state),
  sendCodeSuccess: !!getBackendSendCodeData(state),
  error: getBackendUserError(state),
})

const mapDispatchToProps = {
  patchUser: fetchPatchUserActions.requestAction,
  sendCode: fetchSendSecurityCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile)
