import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '../../lib/translate'
import { fetchDownloadFileActions } from '../../redux/actions'
import {
  getBackendCountryDepartmentData,
  isBackendDownloadFileFetching,
} from '../../redux/selectors'

import CustomSaveButton from './CustomSaveButton'

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const DownloadDialogDocuments = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const countryDepartment = useSelector(getBackendCountryDepartmentData)
  const isDownloading = useSelector(isBackendDownloadFileFetching)

  return (
    <Box>
      <Typography variant="body2" className={classes.heading}>
        {translate('requestDialog.moreDownloads')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomSaveButton
            fullWidth
            onClick={() =>
              dispatch(
                fetchDownloadFileActions.requestAction({
                  id: countryDepartment?.privacyFileId,
                  name: translate('fileName.privacy'),
                })
              )
            }
            loading={isDownloading}
            variant="outlined"
            type="button"
          >
            {translate('createSavingsPlan.privacyInfo')}
          </CustomSaveButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomSaveButton
            fullWidth
            onClick={() =>
              dispatch(
                fetchDownloadFileActions.requestAction({
                  id: countryDepartment?.BGBFileId,
                  name: translate('fileName.bgb'),
                })
              )
            }
            loading={isDownloading}
            variant="outlined"
            type="button"
          >
            {translate('createSavingsPlan.downloadBGB')}
          </CustomSaveButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomSaveButton
            fullWidth
            onClick={() =>
              dispatch(
                fetchDownloadFileActions.requestAction({
                  id: countryDepartment?.priceListFileId,
                  name: translate('fileName.prices'),
                })
              )
            }
            loading={isDownloading}
            variant="outlined"
            type="button"
          >
            {translate('createSavingsPlan.prices')}
          </CustomSaveButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DownloadDialogDocuments
