import { Box, Button, Card, CardContent, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import some from 'lodash/some'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  CheckboxAdapter,
  CreatePlanRequestControlDialog,
  CustomSaveButton,
  FormLayout,
  VoucherCard,
} from '../..'
import { GOOGLE_TAG_MANAGER, METAL_TYPE, ROUTES } from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useDialog from '../../../lib/customHooks/useDialog'
import { transformDataForRequest } from '../../../lib/transform'
import { useTranslate } from '../../../lib/translate'
import { required } from '../../../lib/validation'
import { fetchCreateSavingsPlanRequestActions, storeCreatePlanValues } from '../../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getFormCreatePlanData,
  getFormCreatePlanPageData,
  isBackendCreatePlanFetching,
} from '../../../redux/selectors'
import InfoDialog from '../../common/InfoDialog'
import { WORKFLOW_PAGE_1 } from '../planAdjustment/PlanAdjustmentPage1'

export const WORKFLOW_PAGE = 'pageFive'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  infoText: {
    marginLeft: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  infoIcon: {
    fontSize: 18,
  },
  bgbLoadingIndicator: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}))

const ContractorForm = ({ isZollfreilager }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const countryDepartment = useSelector(getBackendCountryDepartmentData)

  const [infoDialogContent, setInfoDialogContent] = useState()

  const handleOpenInfoDialog = (title, infoText) => {
    setInfoDialogContent({ title, infoText })
  }

  const handleCloseInfoDialog = () => {
    setInfoDialogContent(null)
  }

  const CopyReceivedLabel = (
    <Typography>
      {translate('formFields.checkboxes.copyReceived1')}{' '}
      <Link to={`${ROUTES.BGB}?country=${countryDepartment?.countryCode}`} target="_blank">
        {translate('formFields.checkboxes.bgb')}
      </Link>
      {` ${translate('formFields.checkboxes.copyReceived2')}`}
    </Typography>
  )
  const TakeNoticeLabel = (
    <Typography>
      <Link to={`${ROUTES.PRIVACY_INFO}?country=${countryDepartment?.countryCode}`} target="_blank">
        {translate('createSavingsPlan.privacyInfo')}
      </Link>{' '}
      {translate('formFields.checkboxes.processData')}
    </Typography>
  )

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Typography variant="body1" component="div" paragraph></Typography>
          <Box mb={3} display="flex" flexDirection="column">
            <Field
              name="copyReceived"
              component={CheckboxAdapter}
              validate={required}
              label={CopyReceivedLabel}
            />
            <Box ml={4}>
              <Typography variant="caption" color="textSecondary">
                {translate('createSavingsPlan.receiveCopyInfo')}
              </Typography>
            </Box>
          </Box>
          <Box mb={3}>
            <Field
              name="noVerbalAgreements"
              component={CheckboxAdapter}
              validate={required}
              label={translate('formFields.checkboxes.noVerbalAgreements')}
            />
          </Box>
          <Box mb={3}>
            <Field
              name="legalBind"
              component={CheckboxAdapter}
              validate={required}
              label={
                <Typography>
                  {translate('formFields.checkboxes.legalBind')}
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOpenInfoDialog(
                        translate('createSavingsPlan.legalBindInfoTitle'),
                        translate('createSavingsPlan.legalBindInfo')
                      )
                    }
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
                  </IconButton>
                </Typography>
              }
            />
          </Box>
          <Box mb={3}>
            <Field
              name="noPoliticallyExpose"
              initialValue={false}
              component={CheckboxAdapter}
              label={
                <Typography>
                  {translate('formFields.checkboxes.noPoliticallyExpose')}
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOpenInfoDialog(
                        translate('createSavingsPlan.pepInfoTitle'),
                        translate('createSavingsPlan.pepInfo')
                      )
                    }
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
                  </IconButton>
                </Typography>
              }
            />
            <Box ml={4}>
              <Typography variant="caption" color="textSecondary">
                {translate('createSavingsPlan.pepCheckboxInfo')}
              </Typography>
            </Box>
          </Box>
          <Box mb={3}>
            <Field
              name="beneficialOwner"
              component={CheckboxAdapter}
              validate={required}
              label={translate('formFields.checkboxes.beneficialOwner')}
            />
          </Box>
          {isZollfreilager && (
            <Box mb={3}>
              <Field
                name="informedUser"
                component={CheckboxAdapter}
                validate={required}
                label={translate('formFields.checkboxes.informedUser')}
              />
            </Box>
          )}
          <Box mb={3}>
            <Field
              name="processData"
              component={CheckboxAdapter}
              validate={required}
              label={TakeNoticeLabel}
            />
          </Box>
        </CardContent>
      </Card>
      <InfoDialog
        open={!!infoDialogContent}
        onClose={handleCloseInfoDialog}
        {...infoDialogContent}
      />
    </Box>
  )
}

const CreateSavingsPlanPageFive = ({
  createPlanValues,
  createSavingsPlanRequest,
  isFetching,
  isZollfreilager,
  storeValues,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const pushToDataLayer = useDataLayer()
  const wasVerified = !!createPlanValues.pageFour?.verificationDocument

  const [showControlDialog, { openDialog: openControlDialog, closeDialog: closeControlDialog }] =
    useDialog()
  const [requestData, setRequestData] = useState()

  const onSubmit = values => {
    const transformedData = transformDataForRequest({
      ...createPlanValues.pageOne,
      ...createPlanValues.pageThree,
      ...values,
    })
    setRequestData(transformedData)
    openControlDialog()
  }

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.AGREEMENT,
    })
  }, [pushToDataLayer])
  const onCompleteSubmit = pepOffice => {
    createSavingsPlanRequest({
      ...requestData,
      history,
      pepOffice,
      onSuccess: () => {
        pushToDataLayer({
          event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
          funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.COMPLETION,
        })
        pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_COMPLETE })
      },
    })
  }

  const handleGoBack = values => {
    storeValues(values)
    history.goBack()
  }

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={`${translate('createSavingsPlan.acceptance')}`}
      pageCount={wasVerified ? '5 / 5' : '4 / 4'}
      wideLayout
      showClose
      isSignedIn
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={
            { informedUser: false, ...createPlanValues?.pageFive } || {
              informedUser: false,
            }
          }
          mutators={{
            resetSelectedVoucherMetal: ([name], state, utils) => {
              utils.changeValue(state, name, () => undefined)
            },
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <VoucherCard />
              <ContractorForm isZollfreilager={isZollfreilager} />
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => handleGoBack(values)}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('actions.submit')}</CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
      <CreatePlanRequestControlDialog
        data={requestData}
        open={showControlDialog}
        onClose={closeControlDialog}
        handleSubmit={onCompleteSubmit}
        isFetching={isFetching}
      />
    </FormLayout>
  )
}

const ZOLLFREILAGER_METALS = [METAL_TYPE.SILVER, METAL_TYPE.PLATINUM, METAL_TYPE.PALLADIUM]

const mapStateToProps = state => ({
  createPlanValues: getFormCreatePlanData(state),
  isZollfreilager: some(
    getFormCreatePlanPageData(WORKFLOW_PAGE_1)(state),
    (value, key) => ZOLLFREILAGER_METALS.includes(key) && value
  ),
  isFetching: isBackendCreatePlanFetching(state),
})

const mapDispatchToProps = dispatch => ({
  createSavingsPlanRequest: payload =>
    dispatch(fetchCreateSavingsPlanRequestActions.requestAction(payload)),
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageFive)
