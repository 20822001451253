import { gql } from '@apollo/client'

export const GET_USER_DATA = gql`
  query User {
    user
  }
`

export const GET_FILES = gql`
  query GetFiles {
    customFiles
  }
`

export const GET_SAVINGS_PLANS = gql`
  query GetSavingsPlans {
    savingsPlans
  }
`

export const GET_PRODUCTS = gql`
  query GetProducts {
    products
  }
`

export const GET_REQUESTS = gql`
  query GetRequests {
    requests
  }
`

export const GET_TRANSACTIONS = gql`
  query GetTransactions {
    transactions
  }
`

export const EXCHANGE_RATES = gql`
  query ExchangeRates($date: String!) {
    exchangeRates(date: $date)
  }
`

export const GET_INVOICES = gql`
  query Invoices {
    invoices
  }
`

export const GET_CUSTOMER_MESSAGES = gql`
  query GetCustomerMessages {
    notifications
  }
`

export const GET_COUNTRY_DEPARTMENT = gql`
  query GetCountryDepartment($id: String!) {
    countryDepartment(id: $id)
  }
`

export const GET_COUNTRY_DEPARTMENT_BY_COUNTRY_CODE = gql`
  query GetCountryDepartmentByCountryCode($countryCode: String!) {
    countryDepartmentByCountryCode(countryCode: $countryCode)
  }
`

export const GET_METALS = gql`
  query GetMetals {
    metals
  }
`

export const GET_GOOGLE_PLACES = gql`
  query GetGooglePlaces($input: String, $placeId: String) {
    googlePlaces(input: $input, placeId: $placeId)
  }
`

export const GET_VALIDATE_IBAN = gql`
  query GetValidateIban($iban: String!) {
    validateIban(iban: $iban)
  }
`

export const GET_ACCOUNT_BRAND = gql`
  query GetAccountBrand {
    accountBrand
  }
`

export const GET_SAVING_PLAN_LOGS = gql`
  query GetSavingPlanLogs {
    savingPlanLogs
  }
`

export const GET_PERFORMANCES = gql`
  query GetPerformances {
    performances
  }
`
