import { Box, makeStyles, Typography } from '@material-ui/core'
import find from 'lodash/find'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { CUSTOMER_GROUP, REQUEST_RECORD_TYPE } from '../../config'
import { useTranslate } from '../../lib/translate'
import { getBackendRequestsData, getBackendUserData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
}))

const getPageTitle = request => {
  switch (request?.recordTypeId) {
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return request?.physicalDelivery
        ? 'createSavingsPlan.pdfShippingDownloadTitle'
        : 'createSavingsPlan.pdfTransferDownloadTitle'
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return 'createSavingsPlan.pdfcancelDownloadTitle'
    default:
      return 'createSavingsPlan.pdfDownloadTitle'
  }
}

const DownloadInstructions = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const { requestId } = useParams()

  const requests = useSelector(getBackendRequestsData)
  const { customerGroup } = useSelector(getBackendUserData)
  const request = find(requests, { id: requestId })
  const title = translate(getPageTitle(request))
  const isCreate = request?.recordTypeId === REQUEST_RECORD_TYPE.CREATE.id

  return (
    <>
      <Typography variant="h6" className={classes.bold}>
        {title}
      </Typography>
      {isCreate ? (
        <>
          <Typography paragraph>
            {translate('createSavingsPlan.pdfDownloadInfoText1SignFree')}
          </Typography>
          <Typography paragraph>
            {translate('createSavingsPlan.pdfDownloadInfoText2SignFree')}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              1.
            </Box>
            {customerGroup === CUSTOMER_GROUP.EMPLOYEE
              ? translate('createSavingsPlan.pdfDownloadInfo1SignFreeEmployee')
              : translate('createSavingsPlan.pdfDownloadInfo1SignFree')}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              2.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfo2SignFree')}
          </Typography>
          <Typography>{translate('createSavingsPlan.pdfDownloadInfoText3SignFree')}</Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              1.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfoOne', {
              request: title,
            })}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              2.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfoTwo')}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              3.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfoThree')}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              4.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfo4')}
          </Typography>
          <Typography variant="body1" paragraph>
            <Box fontWeight="fontWeightBold" component="span" mr={1}>
              5.
            </Box>
            {translate('createSavingsPlan.pdfDownloadInfo5')}
          </Typography>
        </>
      )}
    </>
  )
}

export default DownloadInstructions
