import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

import {
  getBackendProductsData,
  getBackendSavingsPlansData,
  getRemainingGram,
} from '../../redux/selectors'
import { setRemainingGram } from '../../redux/actions'

/**
 * Custom Hook for Payout and Cancellation flow used in DepositOverviewCard
 * Calculates the Remaining Gram available for payout and saves the value in the redux store
 */
const useCalcRemainingGram = (formValues, planId, payout) => {
  const dispatch = useDispatch()
  const remainingGram = useSelector(getRemainingGram)
  const plan = find(useSelector(getBackendSavingsPlansData), {
    id: planId,
  })
  const products = useSelector(getBackendProductsData)

  const blocked = plan?.gram - plan?.availableGram

  useEffect(() => {
    if (isEmpty(formValues)) {
      dispatch(setRemainingGram(payout ? plan?.availableGram : plan?.gram))
    } else {
      const remaining = Object.values(formValues).reduce(
        (result, value) => {
          if (!value?.id || !value.qty) {
            return result
          }
          const product = find(products, { id: value.id })
          return result - product?.grossWeight * value.qty
        },
        payout ? plan?.availableGram : plan?.gram
      )

      dispatch(setRemainingGram(remaining))
    }
  }, [dispatch, formValues, plan?.availableGram, products, payout, plan?.gram])

  return [remainingGram, plan?.gram, blocked || 0]
}

export default useCalcRemainingGram
