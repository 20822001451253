import React, { useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent, Typography, Grid, Divider, Link } from '@material-ui/core'
import get from 'lodash/get'

import { useTranslate } from '../../lib/translate'
import {
  FormLayout,
  TextFieldAdapter,
  DialingCodeAdaper,
  CustomSaveButton,
  PasswordSafetyIndicator,
} from '..'
import {
  validatePhoneNumber,
  required,
  validatePassword,
  validateConfirmPassword,
} from '../../lib/validation'
import { fetchFinishRegisterActions } from '../../redux/actions'
import {
  isBackendFinishRegisterFetching,
  getBackendFinishRegisterError,
  getBackendUserData,
  getBackendCountryDepartmentData,
} from '../../redux/selectors'
import useSnackbar from '../../lib/useSnackbar'
import { usePrevious } from '../../lib/miscellaneous'
import { COUNTRY_DEPARTMENT, ROUTES } from '../../config'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  alignButtonRight: {
    textAlign: 'right',
  },
  passwordSafetyContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
}))

const FinishRegisterNewCustomerPage = ({
  finishRegister,
  isFetching,
  error,
  userData,
  countryDepartment,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()
  const wasPreviouslyFetching = usePrevious(isFetching)
  const showPasswordForm = userData?.createdByClerk

  const onSubmit = ({ dialCode, telNumber, password }) => {
    const phone = get(dialCode, 'dial_code').concat(telNumber)
    finishRegister({
      phone,
      history,
      registerNewCustomer: true,
      password,
    })
  }

  const impressumRoute = `${ROUTES.IMPRESSUM}${
    countryDepartment?.countryCode === COUNTRY_DEPARTMENT.GERMANY.value
      ? '?country=de'
      : '?country=at'
  }`

  useEffect(() => {
    if (wasPreviouslyFetching && !isFetching && error) {
      showSnackbar(error, 'error')
    }
  }, [isFetching, error, wasPreviouslyFetching, showSnackbar])

  return (
    <FormLayout>
      <Box ml={5}>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('authNumberPage.title')}
        </Typography>
        <Typography variant="subtitle2" paragraph>
          {translate('authNumberPage.subtitle')}
        </Typography>
      </Box>
      <Form
        onSubmit={onSubmit}
        validate={userData?.createdByClerk && validateConfirmPassword}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContentPadding}>
                <Typography variant="body2" paragraph>
                  {translate('authNumberPage.body')}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Field
                      name="dialCode"
                      component={DialingCodeAdaper}
                      validate={required}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      name="telNumber"
                      component={TextFieldAdapter}
                      validate={validatePhoneNumber}
                      type="tel"
                      label={translate('formFields.phone')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {showPasswordForm && (
              <Card className={classes.card}>
                <CardContent className={classes.cardContentPadding}>
                  <Typography variant="body2" paragraph>
                    {translate('authNumberPage.setPwInfo')}
                  </Typography>
                  <Field
                    name="password"
                    component={TextFieldAdapter}
                    validate={validatePassword}
                    fullWidth
                    type="password"
                    autoComplete="new-password"
                    label={translate('formFields.pass')}
                  />
                  <Field
                    name="confirmPassword"
                    component={TextFieldAdapter}
                    fullWidth
                    type="password"
                    autoComplete="new-password"
                    label={translate('formFields.confirmPassword')}
                  />
                  <Box className={classes.passwordSafetyContainer}>
                    <Typography variant="subtitle2">{translate('register.security')}</Typography>
                    <PasswordSafetyIndicator password={get(values, 'password')} />
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    {translate('register.passwordHint')}
                  </Typography>
                </CardContent>
              </Card>
            )}
            <Box className={classes.alignButtonRight}>
              <CustomSaveButton loading={isFetching}>{translate('actions.save')}</CustomSaveButton>
            </Box>
          </form>
        )}
      />
      <Box my={2}>
        <Divider />
        <Box mt={1} ml={1}>
          <Link color="textSecondary" href={impressumRoute} target="__blank">
            {translate('drawer.drawerLink.legalInfo')}
          </Link>
        </Box>
      </Box>
    </FormLayout>
  )
}

FinishRegisterNewCustomerPage.propTypes = {
  finishRegister: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  isFetching: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => ({
  isFetching: isBackendFinishRegisterFetching(state),
  error: getBackendFinishRegisterError(state),
  userData: getBackendUserData(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const mapDispatchToProps = {
  finishRegister: fetchFinishRegisterActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishRegisterNewCustomerPage)
