import React from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Card,
  FormGroup,
  CardContent,
  Typography,
  MenuItem,
  Grid,
  Link,
} from '@material-ui/core'
import find from 'lodash/find'
import get from 'lodash/get'

import { useTranslate } from '../../lib/translate'
import config, { COUNTRY_DEPARTMENT, SALUTATION_OPTIONS, TITLE_OPTIONS } from '../../config'
import {
  FormLayout,
  TextFieldAdapter,
  SelectAdapter,
  CheckboxAdapter,
  DatePickerAdapter,
  DialingCodeAdaper,
  CustomSaveButton,
  NationalitiesAdapter,
  AddressAutocompleteAdapter,
} from '..'
import { validatePhoneNumber, required, validateDate } from '../../lib/validation'
import { sanitizeUserDataForForm } from '../../lib/sanitizeData'
import {
  getBackendCountryDepartmentData,
  getBackendUserData,
  isBackendFinishRegisterFetching,
} from '../../redux/selectors'
import { fetchFinishRegisterActions } from '../../redux/actions'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bottomContents: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  infoText: {
    color: theme.palette.text.hint,
  },
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
}))

const salutationOptions = Object.values(SALUTATION_OPTIONS)
const titleOptions = Object.values(TITLE_OPTIONS)

const _ConfirmUserDataForm = ({
  translate,
  classes,
  onSubmit,
  userData,
  isFetching,
  countryDepartment,
}) => {
  const isAT = countryDepartment?.countryCode
    ? countryDepartment.countryCode.toUpperCase() === COUNTRY_DEPARTMENT.AUSTRIA.value
    : false

  return (
    <Form
      initialValues={sanitizeUserDataForForm(userData)}
      onSubmit={onSubmit}
      mutators={{
        autofillAddress: ([addressObj], state, utils) => {
          if (addressObj) {
            Object.entries(addressObj).forEach(([key, val]) => {
              if (!val) {
                return
              }
              utils.changeValue(state, key, () => val)
            })
          }
        },
      }}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="subtitle2" paragraph>
            {translate('finishRegistration.subtitle')}
          </Typography>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Typography variant="subtitle2">
                {translate('finishRegistration.infoText', {
                  salutation: translate(find(salutationOptions, { value: values.salutation })?.key),
                  name: get(values, 'lastName', ''),
                })}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Field
                name="salutation"
                component={SelectAdapter}
                label={translate('formFields.salutation')}
                fullWidth
              >
                {salutationOptions.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {translate(option.key)}
                  </MenuItem>
                ))}
              </Field>
              {isAT && (
                <Field
                  name="title"
                  component={SelectAdapter}
                  label={translate('formFields.title')}
                  fullWidth
                >
                  {titleOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {translate(option.key)}
                    </MenuItem>
                  ))}
                </Field>
              )}
              <Field
                name="firstName"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={translate('formFields.firstName')}
              />
              <Field
                name="lastName"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={translate('formFields.lastName')}
              />
              <Field
                name="dateOfBirth"
                component={DatePickerAdapter}
                openTo="year"
                validate={validateDate}
                fullWidth
                label={translate('formFields.dateOfBirth')}
              />
              <Field name="nationality" component={NationalitiesAdapter} validate={required} />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Field
                name="addressAddition"
                component={TextFieldAdapter}
                fullWidth
                label={translate('formFields.addressAddition')}
              />
              <Field
                name="street"
                component={AddressAutocompleteAdapter}
                formMutator={form.mutators.autofillAddress}
                validate={required}
              />
              <Field
                name="postalCode"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={translate('formFields.postCode')}
              />
              <Field
                name="city"
                component={TextFieldAdapter}
                validate={required}
                fullWidth
                label={translate('formFields.city')}
              />
              <Field
                name="state"
                component={TextFieldAdapter}
                fullWidth
                label={translate('formFields.state')}
              />
              <Field
                name="country"
                component={NationalitiesAdapter}
                isCountryPicker
                validate={required}
                fullWidth
                label={translate('formFields.country')}
              />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Field
                    name="dialCode"
                    component={DialingCodeAdaper}
                    validate={required}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <Field
                    name="phoneNumber"
                    component={TextFieldAdapter}
                    validate={validatePhoneNumber}
                    fullWidth
                    label={translate('formFields.phone')}
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" className={classes.infoText}>
                {translate('finishRegistration.phoneInfoText')}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <FormGroup>
                <Field
                  name="privacyPolicy"
                  component={CheckboxAdapter}
                  validate={required}
                  label={
                    <Typography variant="subtitle2">
                      <Link href={config.externalRoutes.privacyPolicy} target="_blank">
                        {translate('formFields.checkboxes.privacyPolicy')}
                      </Link>
                    </Typography>
                  }
                />
                <Field
                  name="agb"
                  component={CheckboxAdapter}
                  validate={required}
                  label={
                    <Typography variant="subtitle2">
                      <Link href={config.externalRoutes.agb} target="_blank">
                        {translate('formFields.checkboxes.acceptBgb')}
                      </Link>
                    </Typography>
                  }
                />

                {/* REMOVED FOR NOW, TODO: ADD AGAIN AS SOON AS REQUESTED
                         <Field
                          name="newsletter"
                          component={CheckboxAdapter}
                          label={
                            <Typography variant="subtitle2">
                              {translate('formFields.checkboxes.newsletter')}
                            </Typography>
                          }
                        /> */}
              </FormGroup>
            </CardContent>
          </Card>
          <Box className={classes.bottomContents}>
            <CustomSaveButton loading={isFetching}>
              {translate('finishRegistration.continue')}
            </CustomSaveButton>
          </Box>
        </form>
      )}
    />
  )
}

_ConfirmUserDataForm.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const mapStateToPropsUserDataForm = state => ({
  userData: getBackendUserData(state),
  isFetching: isBackendFinishRegisterFetching(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const ConfirmUserDataForm = connect(mapStateToPropsUserDataForm)(_ConfirmUserDataForm)

const FinishRegisterExistingCustomerPage = ({ finishRegister }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const onSubmit = ({
    phoneNumber,
    dialCode,
    newsletter = false,
    nationality,
    country,
    ...values
  }) => {
    const phone = get(dialCode, 'dial_code').concat(phoneNumber)
    finishRegister({
      phone,
      newsletter,
      nationality: nationality.code,
      country: country.code,
      ...values,
      history,
    })
  }

  return (
    <FormLayout>
      <Box>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('finishRegistration.title')}
        </Typography>
        <ConfirmUserDataForm translate={translate} classes={classes} onSubmit={onSubmit} />
      </Box>
    </FormLayout>
  )
}

const mapDispatchToProps = {
  finishRegister: fetchFinishRegisterActions.requestAction,
}

export default connect(null, mapDispatchToProps)(FinishRegisterExistingCustomerPage)
