import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CustomSaveButton, FormLayout, SliderAdapter } from '../..'
import { ROUTES } from '../../../config'
import { transformPayoutCancelRequest } from '../../../lib/transform'
import { useTranslate } from '../../../lib/translate'
import { formatCurrencyForInput } from '../../../lib/util'
import { validateMinMax } from '../../../lib/validation'
import { fetchCreatePayoutRequestActions, storeRequestPayoutValues } from '../../../redux/actions'
import {
  getBackendSavingsPlansData,
  getFormRequestPayoutPageData,
  isBackendCreatePayoutFetching,
} from '../../../redux/selectors'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './RequestPayoutPage1'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_2 } from './RequestPayoutPage2'

export const WORKFLOW_PAGE = 'pageThree'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const RequestPayoutPageThree = ({
  formValues,
  plan,
  storeValues,
  withdrawPhysical,
  page2Data,
  createRequest,
  isFetching,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const initialFormValues = formValues ?? { amountToSell: 50 }
  const metalString = get(plan, 'metal') ? translate(`metals.${plan.metalName?.toLowerCase()}`) : ''
  const availableAmount = get(plan, 'availableEuro')

  const onSubmit = values => {
    storeValues(values)
    if (withdrawPhysical) {
      history.push(ROUTES.REQUEST_PAYOUT_STEP_FOUR)
    } else {
      const preparedRequest = transformPayoutCancelRequest({
        savingsPlan: plan.id,
        amount: values.amountToSell,
        ...page2Data,
      })
      createRequest({ history, values: preparedRequest })
    }
  }

  const handleChange = ({ values, form }) => {
    if (values.amountToSell === availableAmount) {
      form.mutators.setMin()
    } else {
      form.mutators.setMax()
    }
  }

  return (
    <FormLayout title={translate('payoutRequest.payout')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          mutators={{
            setMax: (args, state, utils) => {
              utils.changeValue(state, 'amountToSell', () => availableAmount)
            },
            setMin: (args, state, utils) => {
              utils.changeValue(state, 'amountToSell', () => 50)
            },
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('payoutRequest.headerText', {
                        metal: metalString,
                      })}
                    </Typography>
                    <Field
                      name="amountToSell"
                      component={SliderAdapter}
                      validate={validateMinMax(1, availableAmount)}
                      sliderLabel={translate('cancelPlans.amount')}
                      format={formatCurrencyForInput}
                      formatOnBlur
                      inputProps={{ step: '0.01' }}
                      max={availableAmount}
                      min={1}
                    />
                    <FormControlLabel
                      label={translate('formFields.checkboxes.sellEverything', {
                        metal: metalString,
                      })}
                      control={
                        <Checkbox
                          checked={values?.amountToSell === availableAmount}
                          onChange={() => handleChange({ values, form })}
                          color="primary"
                        />
                      }
                    />
                  </CardContent>
                </Card>
                <Box className={classes.cardContentPadding}>
                  {withdrawPhysical && (
                    <Typography variant="h6" paragraph className={classes.boldText}>
                      {translate('cancelPlans.nextPageInfo')}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const page1Data = getFormRequestPayoutPageData(WORKFLOW_PAGE_1)(state)
  const page2Data = getFormRequestPayoutPageData(WORKFLOW_PAGE_2)(state)
  const savingsPlanId = get(page1Data, 'savingsPlanId')
  return {
    formValues: getFormRequestPayoutPageData(WORKFLOW_PAGE)(state),
    plan: find(getBackendSavingsPlansData(state), { id: savingsPlanId }),
    withdrawPhysical: get(page2Data, 'physicalDelivery', false),
    page2Data,
    isFetching: isBackendCreatePayoutFetching(state),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeRequestPayoutValues(WORKFLOW_PAGE)(values)),
  createRequest: values => dispatch(fetchCreatePayoutRequestActions.requestAction(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageThree)
