import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
// import get from 'lodash/get'

import {
  FinishRegisterNewCustomerPage,
  // FinishRegisterExistingCustomerPage,
  EmailLinkInvalidPage,
} from '..'
import { fetchCountryDepartmentActions, fetchUserDataActions } from '../../redux/actions'
import {
  getBackendUserData,
  isBackendUserFetching,
  getBackendUserError,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    minHeight: '50vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const FinishRegistrationPage = ({ user, fetchUser, isFetching, error, fetchCountryDepartment }) => {
  const classes = useStyles()

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    if (user?.countryDepartment) {
      fetchCountryDepartment({ id: user.countryDepartment })
    }
  }, [fetchCountryDepartment, user?.countryDepartment])

  if (isFetching || (!user && !error)) {
    return (
      <div className={classes.container}>
        <CircularProgress size={100} />
      </div>
    )
  }

  if (error) {
    return <EmailLinkInvalidPage error={error} />
  }

  return <FinishRegisterNewCustomerPage />
  // DISABLED FINISH REGISTER EXISTING FOR NOW
  // return get(user, 'newCustomer') ? (
  //   <FinishRegisterNewCustomerPage />
  // ) : (
  //   <FinishRegisterExistingCustomerPage />
  // )
}

FinishRegistrationPage.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  isFetching: PropTypes.bool,
  error: PropTypes.object,
  fetchCountryDepartment: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: getBackendUserData(state),
  isFetching: isBackendUserFetching(state),
  error: getBackendUserError(state),
})

const mapDispatchToProps = {
  fetchUser: fetchUserDataActions.requestAction,
  fetchCountryDepartment: fetchCountryDepartmentActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishRegistrationPage)
