import { Box, Button, Card, CardContent, Grid, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import {
  AddressAutocompleteAdapter,
  CustomSaveButton,
  DatePickerAdapter,
  FormLayout,
  NationalitiesAdapter,
  SelectAdapter,
  TextFieldAdapter,
} from '../..'
import {
  COUNTRY_DEPARTMENT,
  GOOGLE_TAG_MANAGER,
  ROUTES,
  SALUTATION_OPTIONS,
  TITLE_OPTIONS,
} from '../../../config'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useIsVerified from '../../../lib/customHooks/useIsVerified'
import { sanitizeContractorForForm } from '../../../lib/sanitizeData'
import { useTranslate } from '../../../lib/translate'
import { required, validateDate } from '../../../lib/validation'
import { storeCreatePlanValues } from '../../../redux/actions'
import {
  getBackendCountryDepartmentData,
  getBackendUserData,
  getFormCreatePlanPageData,
} from '../../../redux/selectors'

export const WORKFLOW_PAGE = 'pageTwo'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  textAlignCenter: {
    textAlign: 'center',
  },
}))

const getSalutationOptions = () => Object.values(SALUTATION_OPTIONS)
const getTitleOptions = () => Object.values(TITLE_OPTIONS)

const FirstCard = ({ isAT }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Field
            name="salutation"
            component={SelectAdapter}
            label={translate('formFields.salutation')}
            fullWidth
            disabled
          >
            {getSalutationOptions().map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {translate(option.key)}
              </MenuItem>
            ))}
          </Field>
          {isAT && (
            <Field
              name="title"
              component={SelectAdapter}
              label={translate('formFields.title')}
              fullWidth
              disabled
            >
              {getTitleOptions().map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {translate(option.key)}
                </MenuItem>
              ))}
            </Field>
          )}
          <Field
            name="firstName"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.firstName')}
            disabled
          />
          <Field
            name="lastName"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.lastName')}
            disabled
          />
          <Field
            name="dateOfBirth"
            component={DatePickerAdapter}
            openTo="year"
            validate={validateDate}
            fullWidth
            label={translate('formFields.dateOfBirth')}
            disabled
          />
          <Field name="nationality" component={NationalitiesAdapter} validate={required} disabled />
        </CardContent>
      </Card>
    </Box>
  )
}

const SecondCard = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Field
            name="street"
            component={AddressAutocompleteAdapter}
            validate={required}
            disabled
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Field
                name="addressAddition"
                component={TextFieldAdapter}
                fullWidth
                label={translate('formFields.addressAddition')}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="addressAdditionDoor"
                component={TextFieldAdapter}
                fullWidth
                label={translate('formFields.addressAdditionDoor')}
                disabled
              />
            </Grid>
          </Grid>
          <Field
            name="postalCode"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.postalCodeShort')}
            disabled
          />
          <Field
            name="city"
            component={TextFieldAdapter}
            validate={required}
            fullWidth
            label={translate('formFields.place')}
            disabled
          />
          <Field
            name="state"
            component={TextFieldAdapter}
            fullWidth
            label={translate('formFields.state')}
            disabled
          />
          <Field
            name="country"
            component={NationalitiesAdapter}
            isCountryPicker
            validate={required}
            fullWidth
            label={translate('formFields.country')}
            disabled
          />
        </CardContent>
      </Card>
    </Box>
  )
}

const CreateSavingsPlanPageTwo = ({ storeValues, initialUserData, values, countryDepartment }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isVerified = useIsVerified()
  const pushToDataLayer = useDataLayer()
  const isAT = countryDepartment?.countryCode
    ? countryDepartment.countryCode.toUpperCase() === COUNTRY_DEPARTMENT.AUSTRIA.value
    : false

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.PERSONAL_DATA,
    })
  }, [pushToDataLayer])
  const onSubmit = values => {
    storeValues(values)
    history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_THREE)
  }

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={translate('createSavingsPlan.personalInfos')}
      pageCount={isVerified ? '2 / 4' : '2 / 5'}
      wideLayout
      showClose
      isSignedIn
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={isEmpty(values) ? sanitizeContractorForForm(initialUserData) : values}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FirstCard isAT={isAT} />
              <SecondCard />
              <Button
                size="small"
                component={RouterLink}
                to={ROUTES.PROFILE}
                className={classes.textAlignCenter}
              >
                {translate('createSavingsPlan.changePersonalDataInfo')}
              </Button>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  initialUserData: getBackendUserData(state),
  values: getFormCreatePlanPageData(WORKFLOW_PAGE)(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageTwo)
