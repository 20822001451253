import { Box, Button, Grid, IconButton, Link as MuiLink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useCallback, useMemo } from 'react'

import { useSelector } from 'react-redux'
import useDialog from '../../lib/customHooks/useDialog'
import { useTranslate } from '../../lib/translate'
import { performanceFormatter } from '../../lib/util'
import ChartDialog from './ChartDialog'
import InfoDialog from './InfoDialog'
import PriceDevelopmentChart from './PriceDevelopmentChart'
import SelectRatesRangePopover from './SelectRatesRangePopover'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  performance24h: {
    color: props =>
      props?.performance24h > 0
        ? theme.palette.success.main
        : props?.performance24h < 0
        ? theme.palette.error.main
        : theme.palette.text.primary,
  },
  performanceRange: {
    color: props =>
      props?.performanceRange > 0
        ? theme.palette.success.main
        : props?.performanceRange < 0
        ? theme.palette.error.main
        : theme.palette.text.primary,
  },
  chartContainer: {
    height: 250,
  },
  chartDetailsButton: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

const ChartContainer = () => {
  const translate = useTranslate()

  const metals = useSelector(state => state.backend.query.metals.data ?? [])
  const exchangeRates = useSelector(state => state.backend.query.exchangeRates.data ?? [])
  const chartMetal = useSelector(state => state.app.misc.chartMetal)
  const chartRange = useSelector(state => state.app.misc.exchangeRateRange)

  const findByChartMetal = useCallback(
    exchangeRate => exchangeRate.metalName?.toLowerCase() === chartMetal,
    [chartMetal]
  )
  const performanceRange = useMemo(
    () =>
      exchangeRates.slice().reverse().find(findByChartMetal)?.gramPrice /
        exchangeRates.find(findByChartMetal)?.gramPrice -
      1,
    [exchangeRates, findByChartMetal]
  )

  const performance24h = metals.find(
    metal => metal.name.toLowerCase() === chartMetal
  )?.performance24h

  const classes = useStyles({ performance24h, performanceRange })
  const [isChartInfoDialogOpen, { openDialog: openChartInfo, closeDialog: closeChartInfo }] =
    useDialog()
  const [isChartDialogOpen, { openDialog: openChartDialog, closeDialog: closeChartDialog }] =
    useDialog()

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className={classes.bold}>
              {translate('chart.overview')}
            </Typography>
            <IconButton size="small" onClick={openChartInfo}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
            <SelectRatesRangePopover />
            <Box ml={2}>
              <Typography variant="body2">
                {`${translate('dashboard.x24h')}: `}
                <Box className={classes.performance24h} component="span">
                  {performance24h || performance24h === 0
                    ? performanceFormatter.format(performance24h)
                    : '-'}
                </Box>
              </Typography>
            </Box>
            <Box ml={2}>
              <Typography variant="body2">
                {`${translate(`chart.${chartRange}`)}: `}
                <Box className={classes.performanceRange} component="span">
                  {performanceRange || performanceRange === 0
                    ? performanceFormatter.format(performanceRange)
                    : '-'}
                </Box>
              </Typography>
            </Box>
            <Button onClick={openChartDialog} className={classes.chartDetailsButton}>
              {translate('actions.openDetails')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.chartContainer}>
          <PriceDevelopmentChart small />
        </Grid>
      </Grid>
      <ChartDialog open={isChartDialogOpen} onClose={closeChartDialog} />
      <InfoDialog
        open={isChartInfoDialogOpen}
        onClose={closeChartInfo}
        title={translate('dashboard.chartInfoTitle')}
        infoText={
          <>
            <Typography align="justify">{translate('dashboard.chartInfoText')}</Typography>
            <Typography>{translate('dashboard.chartInfoText2')}</Typography>
            <MuiLink
              color="secondary"
              href="https://www.lbma.org.uk/prices-and-data/precious-metal-prices#/table"
              target="_blank"
            >
              https://www.lbma.org.uk/prices-and-data/precious-metal-prices#/table
            </MuiLink>
          </>
        }
      />
    </>
  )
}

export default ChartContainer
