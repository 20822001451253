import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import size from 'lodash/size'
import some from 'lodash/some'

import { useTranslate } from '../../lib/translate'
import { FormLayout, CodeInput, CustomSaveButton } from '..'
import { fetchVerifyCodeActions, fetchVerifyRequest2FAActions } from '../../redux/actions'
import {
  isBackendVerifyCodeFetching,
  isBackendVerifyRequest2FAFetching,
} from '../../redux/selectors'

const TwoFactorAuthenticationPage = ({
  verifyCode,
  isFetching,
  isVerifyRequest,
  verifyRequest,
}) => {
  const translate = useTranslate()
  const { requestId } = useParams()
  const history = useHistory()
  const [values, setValues] = useState({})

  const onSubmit = () => {
    const code = Object.values(values).join('')
    if (isVerifyRequest) {
      verifyRequest({ requestId, code, history })
    } else {
      verifyCode({ code, history })
    }
  }

  return (
    <FormLayout isSignedIn={!!requestId}>
      <Box mb={5}>
        <Typography variant="h6" gutterBottom>
          <Box fontWeight="fontWeightBold" component="span">
            {translate('twoFactorAuthCheck.title')}
          </Box>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {translate('twoFactorAuthCheck.subtitle')}
        </Typography>
      </Box>
      <Box mb={5}>
        <CodeInput values={values} setValues={setValues} onSubmit={onSubmit} showNewPhone />
      </Box>
      <Box textAlign="right" mb={5}>
        <CustomSaveButton
          disabled={size(values) < 4 || some(values, val => val === '')}
          loading={isFetching}
          onClick={onSubmit}
        >
          {translate('twoFactorAuthCheck.sendButton')}
        </CustomSaveButton>
      </Box>
    </FormLayout>
  )
}

TwoFactorAuthenticationPage.propTypes = {
  verifyCode: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
}

const mapStateToProps = state => ({
  isFetching: isBackendVerifyCodeFetching(state) || isBackendVerifyRequest2FAFetching(state),
})

const mapDispatchToProps = {
  verifyCode: fetchVerifyCodeActions.requestAction,
  verifyRequest: fetchVerifyRequest2FAActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthenticationPage)
