import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ROUTES } from '../../config'
import useDialog from '../../lib/customHooks/useDialog'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { useTranslate } from '../../lib/translate'
import { setIsInitialLogin } from '../../redux/actions'
import {
  getBackendSavingsPlansData,
  getBackendUserData,
  getIsInitialLogin,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },

  dialogContent: {
    paddingBottom: theme.spacing(3),
  },
}))

const NewCustomerContent = ({ handleClose, handleCreateNewPlan }) => {
  const translate = useTranslate()
  const isUnderaged = useIsUnderaged()
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="body1">{translate('initialLoginDialog.infoNewCustomer')}</Typography>
        <Typography variant="body1">{translate('initialLoginDialog.infoNewCustomer2')}</Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6} container justify="center">
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {translate('initialLoginDialog.lookAround')}
          </Button>
        </Grid>
        <Grid item xs={6} container justify="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewPlan}
            disabled={isUnderaged}
          >
            {translate('initialLoginDialog.createPlan')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ExistingCustomerContent = ({ handleClose, handleCreateNewPlan, plans }) => {
  const translate = useTranslate()
  const isUnderaged = useIsUnderaged()

  const singularOrPlural =
    plans.length > 1
      ? translate('initialLoginDialog.planPlural')
      : translate('initialLoginDialog.planSingular')

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {translate('initialLoginDialog.infoExistingCustomer', {
            count: plans?.length,
            singularOrPlural,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6} container justify="center">
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {translate('initialLoginDialog.lookAround')}
          </Button>
        </Grid>
        <Grid item xs={6} container justify="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewPlan}
            disabled={isUnderaged}
          >
            {translate('initialLoginDialog.createAnotherPlan')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const InitialLoginDialog = ({ isInitialLogin, isNewCustomer, plans, setIsInitialLogin }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const [dialogState, { closeDialog }] = useDialog(isInitialLogin)

  const handleCreateNewPlan = () => {
    setIsInitialLogin(false)
    closeDialog()
    history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE)
  }

  const handleClose = () => {
    setIsInitialLogin(false)
    closeDialog()
  }

  return (
    <Dialog fullWidth maxWidth="md" open={dialogState}>
      <DialogTitle disableTypography>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {translate('initialLoginDialog.title')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mb={2}>
          <Typography variant="body1">{translate('initialLoginDialog.subtitle')}</Typography>
          <Typography variant="body1">{translate('initialLoginDialog.subtitleInfo')}</Typography>
        </Box>
        {(isNewCustomer && false) || isEmpty(plans) ? (
          <NewCustomerContent handleClose={handleClose} handleCreateNewPlan={handleCreateNewPlan} />
        ) : (
          <ExistingCustomerContent
            handleClose={handleClose}
            handleCreateNewPlan={handleCreateNewPlan}
            plans={plans}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  isInitialLogin: getIsInitialLogin(state),
  isNewCustomer: get(getBackendUserData(state), 'newCustomer', true),
  plans: getBackendSavingsPlansData(state),
})

const mapDispatchToProps = {
  setIsInitialLogin,
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialLoginDialog)
