import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import ImageIcon from '@material-ui/icons/Image'
import find from 'lodash/find'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  CheckboxAdapter,
  CustomSaveButton,
  DownloadInstructions,
  FormLayout,
  SendToAddress,
} from '..'
import cancelPreview from '../../assets/images/aenderungsantrag.png'
import { REQUEST_RECORD_TYPE, REQUEST_STATUS, ROUTES } from '../../config'
import { formatBytes, usePrevious } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import useSnackbar from '../../lib/useSnackbar'
import { getSendOption } from '../../lib/util'
import {
  fetchCustomFilesActions,
  fetchDownloadFileActions,
  fetchRequestsActions,
  fetchSendViaOtherActions,
  fetchUploadDocumentActions,
} from '../../redux/actions'
import {
  getBackendCustomFilesData,
  getBackendDownloadFileData,
  getBackendRequestsData,
  isBackendDownloadFileFetching,
  isBackendSendViaOtherFetching,
  isBackendUploadDocumentFetching,
} from '../../redux/selectors'
import payoutPreview from './../../assets/images/auszahlungsantrag.png'
import createPreview from './../../assets/images/erstellungsantrag.png'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(5),
  },
  cardImage: {
    height: '15.625rem',
    objectFit: 'contain',
  },
  uploadBox: {
    border: '1px dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: '8px',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    height: '100%',
    color: theme.palette.secondary.main,
  },
  deleteButton: {
    borderRadius: 0,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  marginRight: {
    marginRight: theme.spacing(),
  },
}))

const FILES_TO_DOWNLOAD = {
  PEP: 'PEP',
  REQUEST: 'request',
  BGB: 'BGB',
  PRICES: 'prices',
}

const getPageTitle = request => {
  switch (request?.recordTypeId) {
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return request?.physicalDelivery
        ? 'completeRequestPage.shippingRequestTitle'
        : 'completeRequestPage.transferRequestTitle'
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return 'completeRequestPage.cancelTitle'
    default:
      return 'completeRequestPage.title'
  }
}

const getPreviewForRequestType = requestRecordTypeId => {
  switch (requestRecordTypeId) {
    case REQUEST_RECORD_TYPE.CANCEL.id:
      return cancelPreview
    case REQUEST_RECORD_TYPE.PAYOUT.id:
      return payoutPreview
    default:
      return createPreview
  }
}

const CompleteRequestPage = ({
  customFiles,
  requests,
  isDownloading,
  downloadSuccess,
  isUploading,
  fetchUploadDocument,
  fetchDownloadFile,
  fetchSendViaOther,
  fetchCustomFiles,
  fetchRequests,
  isFetchingSendViaOther,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()

  const { requestId } = useParams()

  const [showUpload, setShowUpload] = useState(false)
  const [hasDownloadedRequest, setHasDownloadedRequest] = useState(false)
  const wasPreviouslyDownloading = usePrevious(isDownloading)
  const requestFileId = get(find(customFiles, { request: requestId }), 'id')
  const request = find(requests, { id: requestId })
  const isCreate = request?.recordTypeId === REQUEST_RECORD_TYPE.CREATE.id

  const handleDownload = (id, type) => () => {
    if (id) {
      switch (type) {
        case FILES_TO_DOWNLOAD.BGB:
          fetchDownloadFile({ id, name: translate('fileName.bgb') })
          break
        case FILES_TO_DOWNLOAD.PRICES:
          fetchDownloadFile({ id, name: translate('fileName.prices') })
          break
        case FILES_TO_DOWNLOAD.REQUEST:
          fetchDownloadFile({ id, request: requestId, name: translate('fileName.request') })
          setHasDownloadedRequest(true)
          break
        default:
          break
      }
    } else {
      showSnackbar('notification.pdfNotFound', 'error')
    }
  }

  const onSubmit = values => {
    if (values.uploadDocument && requestId && !values.sendViaPost) {
      fetchUploadDocument({
        history,
        request: requestId,
        file: values.uploadDocument.file,
      })
    } else if ((values.sendViaPost || values.sendMeViaPost || values.sendMeViaEmail) && requestId) {
      fetchSendViaOther({
        history,
        request: requestId,
        option: getSendOption(values.sendViaPost, values.sendMeViaPost, values.sendMeViaEmail),
      })
    }
  }

  const handleSetFileToUpload = (validity, file) => {
    if (validity.valid) {
      return { file }
    }
  }

  useEffect(() => {
    fetchCustomFiles()
  }, [fetchCustomFiles])

  useEffect(() => {
    if (!find(requests, { id: requestId })) {
      fetchRequests()
    }
  }, [requests, requestId, fetchRequests])

  useEffect(() => {
    if (!isDownloading && wasPreviouslyDownloading && downloadSuccess && hasDownloadedRequest) {
      setShowUpload(true)
    }
  }, [downloadSuccess, hasDownloadedRequest, isDownloading, wasPreviouslyDownloading])

  useEffect(() => {
    const requestStatus = get(request, 'status')

    if (requestStatus && requestStatus !== REQUEST_STATUS.CREATED) {
      setShowUpload(true)
    }
  }, [requestId, request])
  
  if(!request) {
    return (
      <CircularProgress color="secondary" />
    )
  }

  if (isCreate) {
    return (
      <FormLayout
        title={translate('createSavingsPlan.pdfDownloadInfoText2SignFree')}
        wideLayout
        showClose
        isSignedIn
      >
        <Box my={5}>
          <Card>
            <CardContent className={classes.cardContentPadding}>
              <DownloadInstructions />
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between" mb={5}>
          <Button variant="text" onClick={() => history.push(ROUTES.REQUESTS)}>
            {translate('actions.backToRequests')}
          </Button>
          <Button variant="text" onClick={() => history.push(ROUTES.REQUESTS)}>
            {translate('actions.backToDashboard')}
          </Button>
        </Box>
      </FormLayout>
    )
  }

  return (
    <FormLayout title={translate(getPageTitle(request))} wideLayout showClose isSignedIn>
      <Form
        onSubmit={onSubmit}
        mutators={{
          deleteFile: (args, state, utils) => {
            utils.changeValue(state, 'uploadDocument', () => null)
          },
        }}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Box my={5}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <Box mb={3}>
                    <CardMedia
                      component="img"
                      alt="philoro"
                      image={getPreviewForRequestType(request?.recordTypeId)}
                      title={translate('createSavingsPlan.pdfPreview')}
                      className={classes.cardImage}
                    />
                  </Box>
                  <CustomSaveButton
                    fullWidth
                    onClick={handleDownload(requestFileId, FILES_TO_DOWNLOAD.REQUEST)}
                    loading={isDownloading}
                    variant={showUpload ? 'text' : 'contained'}
                    type="button"
                  >
                    {translate('createSavingsPlan.requestDownload')}
                  </CustomSaveButton>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Card>
                    <CardContent className={classes.cardContentPadding}>
                      <Collapse in={!showUpload}>
                        <DownloadInstructions />
                      </Collapse>
                      <Collapse in={showUpload}>
                        <Collapse in={!!values.uploadDocument}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={2}
                            key={get(values, 'uploadDocument.file.name')}
                            id="fileBox"
                          >
                            <Box display="flex" alignItems="center" mb={3}>
                              <ImageIcon className={classes.marginRight} />
                              <Typography variant="body2">
                                {`${get(values, 'uploadDocument.file.name')} - ${formatBytes(
                                  get(values, 'uploadDocument.file.size')
                                )}`}
                              </Typography>
                            </Box>
                            <Button
                              fullWidth
                              size="large"
                              variant="outlined"
                              endIcon={<DeleteIcon />}
                              className={classes.deleteButton}
                              onClick={form.mutators.deleteFile}
                            >
                              {translate('actions.delete')}
                            </Button>
                          </Box>
                        </Collapse>
                        <Collapse in={!values.uploadDocument}>
                          <Collapse in={!values.sendViaPost}>
                            <Typography variant="body1" paragraph>
                              {translate('createSavingsPlan.pdfUploadInfo')}
                            </Typography>
                            <Box mt={4} mb={4}>
                              <Field name="uploadDocument">
                                {({ input }) => (
                                  <input
                                    accept="application/pdf"
                                    id="uploadInput"
                                    type="file"
                                    hidden
                                    onChange={({
                                      target: {
                                        validity,
                                        files: [file],
                                      },
                                    }) => input.onChange(handleSetFileToUpload(validity, file))}
                                  />
                                )}
                              </Field>
                              <label htmlFor="uploadInput" className={classes.uploadBox}>
                                <Button
                                  variant="text"
                                  component="span"
                                  fullWidth
                                  className={classes.uploadButton}
                                  endIcon={<CloudUploadIcon />}
                                >
                                  {translate('actions.upload')}
                                </Button>
                              </label>
                            </Box>
                          </Collapse>
                          <Field
                            name="sendViaPost"
                            component={CheckboxAdapter}
                            label={
                              <Typography variant="subtitle2">
                                {translate('createSavingsPlan.sendViaPost')}
                              </Typography>
                            }
                          />
                          <Collapse in={values.sendViaPost}>
                            <SendToAddress />
                          </Collapse>
                        </Collapse>
                      </Collapse>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" mb={5}>
              <Button variant="text" onClick={() => history.push(ROUTES.REQUESTS)}>
                {translate('actions.backToRequests')}
              </Button>
              <CustomSaveButton
                disabled={!isCreate && !values.uploadDocument && !values.sendViaPost}
                loading={isUploading || isFetchingSendViaOther}
                variant={isCreate ? 'text' : 'contained'}
              >
                {isCreate ? translate('actions.backToDashboard') : translate('actions.save')}
              </CustomSaveButton>
            </Box>
          </form>
        )}
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  isDownloading: isBackendDownloadFileFetching(state),
  isUploading: isBackendUploadDocumentFetching(state),
  customFiles: getBackendCustomFilesData(state),
  requests: getBackendRequestsData(state),
  isFetchingSendViaOther: isBackendSendViaOtherFetching(state),
  downloadSuccess: !!getBackendDownloadFileData(state),
})

const mapDispatchToProps = {
  fetchDownloadFile: fetchDownloadFileActions.requestAction,
  fetchUploadDocument: fetchUploadDocumentActions.requestAction,
  fetchSendViaOther: fetchSendViaOtherActions.requestAction,
  fetchCustomFiles: fetchCustomFilesActions.requestAction,
  fetchRequests: fetchRequestsActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRequestPage)
