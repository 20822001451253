import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getBackendUserData } from '../../redux/selectors'

const useIsVerified = () => {
  const { verificationFile } = useSelector(getBackendUserData) || {}

  const isVerified = useMemo(() => !!verificationFile, [verificationFile])

  return isVerified
}

export default useIsVerified
