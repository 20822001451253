import { Box, Button, Card, CardContent, Collapse, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import some from 'lodash/some'
import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AutocompleteAdapter, CustomSaveButton, FormLayout, TextFieldAdapter } from '../..'
import {
  AVAILABLE_METALS,
  GOOGLE_TAG_MANAGER,
  METAL_MIN_RATE_NAME,
  METAL_TYPE,
  ROUTES,
  availableMonths,
} from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import useDataLayer from '../../../lib/customHooks/useDataLayer'
import useIsVerified from '../../../lib/customHooks/useIsVerified'
import { useTranslate } from '../../../lib/translate'
import useSnackbar from '../../../lib/useSnackbar'
import { required, validateMin } from '../../../lib/validation'
import { fetchRefreshTokenActions, storeCreatePlanValues } from '../../../redux/actions'
import {
  getBackendMetalsData,
  getBackendUserData,
  getFormCreatePlanPageData,
} from '../../../redux/selectors'
import MetalCardController from '../../common/MetalCardController'

export const WORKFLOW_PAGE = 'pageOne'

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  mb: {
    marginBottom: 10,
  },
}))

const PlanTypeCard = ({ planType, minAmount }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mb={3}>
      <Card>
        <CardContent className={classes.cardContentPadding}>
          <Typography variant="body1" gutterBottom component="span">
            <Box fontWeight="fontWeightBold" mb={3}>
              {translate(`metals.${planType}`)}
            </Box>
          </Typography>
          <Grid container alignItems="center" justify="space-between" spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body2">{translate('formFields.monthlyPerAsset')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name={`${planType}Monthly`}
                component={TextFieldAdapter}
                validate={validateMin(minAmount)}
                className={classes.noBottomMargin}
                format={parseInt}
                formatOnBlur
                min={minAmount}
                type="number"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{translate('formFields.startingPeriod')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name={`${planType}StartMonth`}
                component={AutocompleteAdapter}
                options={availableMonths().map(month => ({
                  label: month.format('MMMM'),
                  value: month.format('YYYY-MM-DD'),
                }))}
                translateLabel={false}
                className={classes.noBottomMargin}
                validate={required}
              />
            </Grid>
          </Grid>
          <Box mt="1.2rem">
            <Typography variant="body1" paragraph className={classes.textHint}>
              {translate('createSavingsPlan.minSavingsAmount', { min: minAmount })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const CreateSavingsPlanPageOne = ({ storeValues, refreshToken, initialValues, userData }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isVerified = useIsVerified()
  const showSnackbar = useSnackbar()
  const displayCurrency = useCurrency()
  const pushToDataLayer = useDataLayer()

  useEffect(() => {
    pushToDataLayer({
      event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
      funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.METAL,
    })
  }, [])
  const onSubmit = values => {
    if (!some(Object.values(METAL_TYPE), type => !!values[type])) {
      showSnackbar('notification.atLeastOneAsset', 'info')
    } else {
      storeValues(values)
      pushToDataLayer(
        {
          event: GOOGLE_TAG_MANAGER.EVENT.SAVINGS_PLAN_STEP,
          funnelStep: GOOGLE_TAG_MANAGER.FUNNEL_STEP.METAL,
        },
        values
      )
      history.push(ROUTES.CREATE_SAVINGS_PLAN_STEP_TWO)
    }
  }

  useEffect(() => {
    refreshToken()
  }, [refreshToken])

  return (
    <FormLayout
      title={translate('createSavingsPlan.title')}
      subTitle={translate('createSavingsPlan.monthlyPlan')}
      pageCount={isVerified ? '1 / 4' : '1 / 5'}
      wideLayout
      showClose
      isSignedIn
    >
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          subscription={{
            initialValues: true,
            values: true,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={5}>
                <Box mb={5}>
                  <Typography variant="h5" align="center">
                    {translate('createSavingsPlan.selectMetalTitle')}
                  </Typography>
                </Box>
                <Grid container alignItems="center" spacing={2}>
                  {AVAILABLE_METALS.map(({ metalType }) => (
                    <Grid item xs={6} key={metalType} container justify="center">
                      <MetalCardController metalName={metalType} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Collapse in={some(Object.values(METAL_TYPE), type => !!values[type])}>
                <Box mb={5}>
                  <Typography variant="h6" align="center">
                    {translate('createSavingsPlan.selectAmountTitle')}
                  </Typography>
                </Box>
              </Collapse>
              {AVAILABLE_METALS.map(({ metalType }) => (
                <Collapse in={values[metalType] || false} unmountOnExit key={metalType}>
                  <PlanTypeCard
                    planType={metalType}
                    minAmount={userData[METAL_MIN_RATE_NAME[metalType]]}
                  />
                </Collapse>
              ))}
              <Box mb={5}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Typography variant="body1" gutterBottom component="span">
                      <Box fontWeight="fontWeightBold" mb={3}>
                        {translate('createSavingsPlan.savingsRate')}
                      </Box>
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <Typography variant="body2" gutterBottom>
                          {translate('formFields.monthlyTotal')}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {displayCurrency(
                            Object.entries(values).reduce(
                              (sum, [key, value]) => (
                                // eslint-disable-next-line no-sequences
                                key.includes('Monthly') ? (sum += +value) : sum, sum
                              ),
                              0
                            )
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  initialValues: getFormCreatePlanPageData(WORKFLOW_PAGE)(state),
  metals: getBackendMetalsData(state),
  userData: getBackendUserData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCreatePlanValues(WORKFLOW_PAGE)(values)),
  refreshToken: () => dispatch(fetchRefreshTokenActions.requestAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSavingsPlanPageOne)
