import { LOGLEVEL } from './constants'
import { version } from '../../package.json'

const devEnv = process.env.NODE_ENV === 'development'

const getEnvOverrides = () => {
  if (devEnv) {
    return {
      logging: {
        enabledLevels: Object.values(LOGLEVEL),
      },
    }
  }
  return {}
}

const apiBaseUrl = devEnv ? 'http://localhost:3001' : global.PHILORO && global.PHILORO.API_BASEURL

export const getApiBaseUrl = () => `${apiBaseUrl}/graphql`

export const getApiBaseUrlNoGraphql = () => `${apiBaseUrl}`

const config = {
  version,
  logging: {
    enabledLevels: [LOGLEVEL.error, LOGLEVEL.warn],
  },
  externalRoutes: {
    agb: 'https://philoro.de/agb',
    faq: 'https://edelmetallsparer.at/faq/',
    bgbGermany: 'https://philoro.de/uploads/philoro%20Edelmetallsparplan%20Antrag_D.pdf',
    bgbAustria: 'https://philoro.at/uploads/philoro_Edelmetallsparplan_Antrag_AT.pdf',
    pricesAT:
      'https://edelmetallsparer.at/wp-content/uploads/sites/6/2024/03/240314_philoro-Edelmetallsparplan_Preisblatt-AT.pdf',
    pricesDE:
      'https://edelmetallsparer.de/wp-content/uploads/2024/03/240315_philoro-Edelmetallsparplan_Preisblatt-DE.pdf',
    loginEMA: devEnv
      ? 'http://localhost:3001/login?redirect=emsp'
      : global.PHILORO && global.PHILORO.LOGIN_EMA,
  },
  isDemo: devEnv ? false : global.PHILORO && global.PHILORO.IS_DEMO,
  disableSagaErrorHandling: devEnv,
  ...getEnvOverrides(),
}

export default config
