import { Box, Button, Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CheckboxAdapter, CustomSaveButton, DatePickerAdapter, FormLayout } from '../..'
import { METAL_TYPE, ROUTES } from '../../../config'
import useCurrency from '../../../lib/customHooks/useCurrency'
import { useTranslate } from '../../../lib/translate'
import { parseDateToEndOfMonth } from '../../../lib/util'
import { validateDateToBeAfterDate } from '../../../lib/validation'
import { fetchCancelPlanRequestActions, storeCancelPlanValues } from '../../../redux/actions'
import {
  getBackendSavingsPlansData,
  getFormCancelPlanPageData,
  isBackendCancelPlanFetching,
} from '../../../redux/selectors'
import { WORKFLOW_PAGE as WORKFLOW_PAGE_1 } from './CancelSavingsPlanPage1'

export const WORKFLOW_PAGE = 'pageTwo'

export const MIN_CANCEL_DATE = moment().add(2, 'weeks')

const useStyles = makeStyles(theme => ({
  contentColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  inlineButton: {
    marginBottom: '1.25rem',
    marginLeft: '1rem',
  },
}))

const CancelSavingsPlanPageTwo = ({ initialValues, storeValues, plan, cancelPlan, isFetching }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const displayCurrency = useCurrency()

  const { currentValue } = plan || {}

  const onSubmit = ({ physicalDelivery = false, ...values }) => {
    if (physicalDelivery) {
      storeValues({ physicalDelivery, ...values })
      history.push(ROUTES.CANCEL_SAVINGS_PLAN_STEP_THREE)
    } else {
      const savingsPlan = get(plan, 'id')
      const amount = get(savingsPlan, 'currentValue')
      cancelPlan({
        history,
        savingsPlan,
        amount,
        physicalDelivery,
        ...values,
      })
    }
  }

  return (
    <FormLayout title={translate('cancelPlans.cancelPlan')} wideLayout showClose isSignedIn>
      <Box mt={10} mb={10} className={classes.contentColumns}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3} ml={5}>
                <Typography variant="h6" paragraph className={classes.boldText}>
                  {translate('cancelPlans.availableAmount')}
                </Typography>
                <Typography variant="h4" paragraph color="primary" className={classes.boldText}>
                  {displayCurrency(currentValue)}
                </Typography>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardContent className={classes.cardContentPadding}>
                    <Box mb={3}>
                      <Typography variant="h6" paragraph className={classes.boldText}>
                        {translate('cancelPlans.cancelPlanFrom')}
                      </Typography>
                      <Typography variant="body1" paragraph className={classes.greyText}>
                        {translate('cancelPlans.cancelPlanFromText')}
                      </Typography>
                      <Field
                        name="cancellationDate"
                        component={DatePickerAdapter}
                        minDate={MIN_CANCEL_DATE}
                        views={['month', 'year']}
                        validate={validateDateToBeAfterDate(MIN_CANCEL_DATE)}
                        parse={parseDateToEndOfMonth}
                        label={translate('cancelPlans.fields.cancelPlanFromDate')}
                        fullWidth
                      />
                    </Box>
                    {METAL_TYPE.GOLD === plan?.metalName?.toLowerCase() && (
                      <Box mb={3}>
                        <Typography variant="h6" paragraph className={classes.boldText}>
                          {translate('cancelPlans.metalDelivery')}
                        </Typography>
                        <Field
                          name="physicalDelivery"
                          component={CheckboxAdapter}
                          label={translate('cancelPlans.metalDeliveryText')}
                        />
                        <Box mt={2} ml={4} mr={2}>
                          <Typography variant="body1">
                            {translate('cancelPlans.metalDeliveryText2')}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching}>
                  {translate('finishRegistration.continue')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const savingsPlanId = get(getFormCancelPlanPageData(WORKFLOW_PAGE_1)(state), 'savingsPlanId')
  return {
    initialValues: getFormCancelPlanPageData(WORKFLOW_PAGE)(state),
    plan: find(getBackendSavingsPlansData(state), { id: savingsPlanId }),
    isFetching: isBackendCancelPlanFetching(state),
  }
}

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCancelPlanValues(WORKFLOW_PAGE)(values)),
  cancelPlan: values => dispatch(fetchCancelPlanRequestActions.requestAction(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CancelSavingsPlanPageTwo)
