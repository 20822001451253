import { takeLatest, put, take } from 'redux-saga/effects'

import * as appActions from './actions'
import config, { ROUTES, POST_AUTH_ACTION } from '../../config'
import * as backendActions from '../backend/actions'

function* appLoginSaga({ payload = {} }) {
  const { email, password, history } = payload

  yield put(backendActions.fetchLoginActions.requestAction({ email, password }))
  const loginResult = yield take([
    backendActions.fetchLoginActions.successType,
    backendActions.fetchLoginActions.failureType,
  ])

  if (loginResult.type === backendActions.fetchLoginActions.successType) {
    const { payload } = loginResult
    const { action } = payload || {}

    yield put(appActions.appLoginSuccess(payload))

    if (action === POST_AUTH_ACTION.REDIRECT) {
      window.location.replace(config.externalRoutes.loginEMA)
    } else if (action === POST_AUTH_ACTION.EMAIL) {
      yield put(backendActions.fetchUserDataActions.successAction({ email }))
      yield put(backendActions.fetchResendVerifyMailActions.requestAction({ email }))
      history.push(ROUTES.CONFIRMATION_SENT)
    } else if (action === POST_AUTH_ACTION['2FA']) {
      history.push(ROUTES.FINISH_REGISTRATION)
    } else if (action === POST_AUTH_ACTION.COMPLETE) {
      history.push(ROUTES.TWO_FA)
    } else {
      yield put(appActions.showNotification('notification.unexpectedResponse', 'error'))
    }
  }
}

function* handleFailureNotificationSaga({ payload = {} }) {
  yield put(appActions.showNotification(payload, 'error'))
}

const appSagas = () => [
  takeLatest(appActions.APP_LOGIN, appLoginSaga),
  takeLatest(
    [
      backendActions.fetchSendSecurityCodeActions.failureType,
      backendActions.fetchStartRegisterActions.failureType,
      backendActions.fetchVerifyPhoneActions.failureType,
      backendActions.fetchFinishRegisterActions.failureType,
      backendActions.fetchVerifyCodeActions.failureType,
      backendActions.fetchCreateSavingsPlanRequestActions.failureType,
      backendActions.fetchResendVerifyMailActions.failureType,
      backendActions.fetchDownloadFileActions.failureType,
      backendActions.fetchUploadDocumentActions.failureType,
      backendActions.fetchSendViaOtherActions.failureType,
      backendActions.fetchPatchUserActions.failureType,
      backendActions.fetchUploadVerificationDocumentActions.failureType,
      backendActions.fetchChangePlanNameActions.failureType,
      backendActions.fetchChangeSecurityDetailsActions.failureType,
      backendActions.fetchConfirmNewMailActions.failureType,
      backendActions.fetchConfirmNewPhoneActions.failureType,
      backendActions.fetchCreatePayoutRequestActions.failureType,
      backendActions.fetchCancelPlanRequestActions.failureType,
      backendActions.fetchAdjustPlanActions.failureType,
      backendActions.fetchVerifyRequest2FAActions.failureType,
      backendActions.fetchSetCustomerMessagesActions.failureType,
      backendActions.fetchRequestCallbackActions.failureType,
      backendActions.fetchDeleteCacheActions.failureType,
      backendActions.fetchVerifyVoucherActions.failureType,
      backendActions.fetchDeleteRequestActions.failureType,
      backendActions.fetchMigrateActions.failureType,
      backendActions.fetchDeleteAdjustmentActions.failureType,
      backendActions.fetchApplyVoucherActions.failureType,
    ],
    handleFailureNotificationSaga
  ),
]

export default appSagas
