import React from 'react'
import { Box, makeStyles, Typography, Container, Paper, Grid, CardMedia } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import { COUNTRY_DEPARTMENT } from '../../config'
import useAppLogo from '../../lib/customHooks/useAppLogo'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  img: {
    maxHeight: '4rem',
    width: 'unset',
    objectFit: 'contain',
  },
}))

const PrivacyInfoPage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const appLogo = useAppLogo()

  const countryParam = queryParams.get('country')

  const countryCode =
    countryParam &&
    Object.values(COUNTRY_DEPARTMENT).some(it => it.tld === countryParam.toLowerCase())
      ? countryParam.toUpperCase()
      : 'AT'

  return (
    <Container maxWidth="md">
      <Paper>
        <Box p={4} minHeight="100vh">
          <Box my={3}>
            <CardMedia className={classes.img} component="img" alt="App Logo" image={appLogo} />
          </Box>
          <Typography variant="h4" align="center" paragraph>
            {translate(`privacyInfo.title${countryCode}`)}
          </Typography>
          <Typography align="justify" paragraph>
            {translate('privacyInfo.sub0sub1')}
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub1')}
          </Typography>
          <Box pl={5}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography>{translate('privacyInfo.sub1sub1-1')}</Typography>
                <Typography>{translate('privacyInfo.sub1sub2-1')}</Typography>
                <Typography>{translate('privacyInfo.sub1sub3-1')}</Typography>
                {['AT', 'DE'].includes(countryCode) && (
                  <Typography>{translate('privacyInfo.sub1sub4-1')}</Typography>
                )}
                <Typography paragraph>{translate('privacyInfo.sub1sub5-1')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{translate(`privacyInfo.sub1sub1-2${countryCode}`)}</Typography>
                <Typography>{translate(`privacyInfo.sub1sub2-2${countryCode}`)}</Typography>
                <Typography>{translate(`privacyInfo.sub1sub3-2${countryCode}`)}</Typography>
                {['AT', 'DE'].includes(countryCode) && (
                  <Typography>{translate(`privacyInfo.sub1sub4-2${countryCode}`)}</Typography>
                )}
                <Typography paragraph>
                  {translate(`privacyInfo.sub1sub5-2${countryCode}`)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate(`privacyInfo.sub2`)}
          </Typography>
          <Box pl={5}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography>{translate('privacyInfo.sub2sub1-1')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub2-1')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub3-1')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub4-1')}</Typography>
                <Typography paragraph>{translate('privacyInfo.sub2sub5-1')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{translate('privacyInfo.sub2sub1-2')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub2-2')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub3-2')}</Typography>
                <Typography>{translate('privacyInfo.sub2sub4-2')}</Typography>
                <Typography paragraph>{translate('privacyInfo.sub2sub5-2')}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub3')}
          </Typography>
          <Box pl={5}>
            <Typography paragraph align="justify">
              {translate(`privacyInfo.sub3sub1${countryCode}`)}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub4')}
          </Typography>
          <Box pl={5}>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub4sub1')}
            </Typography>
            <Typography paragraph align="justify">
              {translate('privacyInfo.sub4sub2')}
            </Typography>
            <Typography className={classes.bold} gutterBottom align="justify">
              {translate('privacyInfo.sub4sub3')}
            </Typography>
            <Box pl={2}>
              <Typography paragraph align="justify">
                {translate(`privacyInfo.sub4sub3sub1${countryCode}`)}
              </Typography>
            </Box>
            <Typography paragraph align="justify">
              {translate('privacyInfo.sub4sub4')}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub5')}
          </Typography>
          <Box pl={5}>
            <Typography paragraph align="justify">
              {translate(`privacyInfo.sub5sub1${countryCode}`)}
            </Typography>
            <Typography className={classes.bold} align="justify">
              {translate(`privacyInfo.sub5sub2${countryCode}`)}
            </Typography>
            <Typography paragraph align="justify">
              {translate('privacyInfo.sub5sub3')}
            </Typography>
            <Typography className={classes.bold} align="justify">
              {translate('privacyInfo.sub5sub4')}
            </Typography>
            <Typography paragraph align="justify">
              {translate('privacyInfo.sub5sub5')}
            </Typography>
            <Typography className={classes.bold} align="justify">
              {translate('privacyInfo.sub5sub6')}
            </Typography>
            <Typography align="justify">{translate('privacyInfo.sub5sub7')}</Typography>
            <Typography align="justify">
              <ul>
                <li>{translate('privacyInfo.sub5sub7dot1')}</li>
                <li>{translate('privacyInfo.sub5sub7dot2')}</li>
                <li>{translate('privacyInfo.sub5sub7dot3')}</li>
                <li>{translate('privacyInfo.sub5sub7dot4')}</li>
                <li>{translate('privacyInfo.sub5sub7dot5')}</li>
                <li>{translate('privacyInfo.sub5sub7dot6')}</li>
                <li>{translate('privacyInfo.sub5sub7dot7')}</li>
                <li>{translate('privacyInfo.sub5sub7dot8')}</li>
                <li>{translate('privacyInfo.sub5sub7dot9')}</li>
              </ul>
            </Typography>
            <Typography className={classes.bold} align="justify">
              {translate(`privacyInfo.sub5sub8${countryCode}`)}
            </Typography>
            <Typography paragraph align="justify">
              {translate(`privacyInfo.sub5sub9${countryCode}`)}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub6')}
          </Typography>
          <Box pl={5}>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub6sub1')}
            </Typography>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub6sub2')}
            </Typography>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub6sub3')}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub7')}
          </Typography>
          <Box pl={5}>
            <Typography paragraph align="justify">
              {translate(`privacyInfo.sub7sub1${countryCode}`)}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub8')}
          </Typography>
          <Box pl={5}>
            <Typography paragraph align="justify">
              {translate(`privacyInfo.sub8sub1${countryCode}`)}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub9')}
          </Typography>
          <Box pl={5}>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub9sub1')}
            </Typography>
            <Typography gutterBottom align="justify">
              {translate(`privacyInfo.sub9sub2${countryCode}`)}
            </Typography>
            <Typography gutterBottom align="justify">
              {translate('privacyInfo.sub9sub3')}
            </Typography>
            <Typography align="justify">{translate('privacyInfo.sub9sub4')}</Typography>
            <Typography align="justify">
              <ul>
                <li>{translate('privacyInfo.sub9sub4dot1')}</li>
                <li>{translate('privacyInfo.sub9sub4dot2')}</li>
              </ul>
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub10')}
          </Typography>
          <Box pl={5}>
            <Typography align="justify">{translate('privacyInfo.sub10sub1')}</Typography>
            <Typography align="justify">
              <ul>
                <li>{translate(`privacyInfo.sub10sub1dot1${countryCode}`)}</li>
                <li>{translate(`privacyInfo.sub10sub1dot2${countryCode}`)}</li>
                <li>{translate(`privacyInfo.sub10sub1dot3${countryCode}`)}</li>
                <li>{translate(`privacyInfo.sub10sub1dot4${countryCode}`)}</li>
                <li>{translate(`privacyInfo.sub10sub1dot5${countryCode}`)}</li>
                {['AT', 'DE'].includes(countryCode) && (
                  <>
                    <li>{translate('privacyInfo.sub10sub1dot6')}</li>
                    <li>{translate(`privacyInfo.sub10sub1dot7${countryCode}`)}</li>
                  </>
                )}
              </ul>
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" className={classes.bold}>
            {translate('privacyInfo.sub11')}
          </Typography>
          <Box pl={5}>
            <Typography align="justify" paragraph>
              {translate('privacyInfo.sub11sub1')}
            </Typography>
          </Box>
          {['AT', 'DE'].includes(countryCode) && (
            <>
              <Typography gutterBottom variant="h6" className={classes.bold}>
                {translate('privacyInfo.sub12')}
              </Typography>
              <Box pl={5}>
                <Typography align="justify" gutterBottom>
                  {translate('privacyInfo.sub12sub1')}
                </Typography>
                <Typography align="justify" gutterBottom>
                  {translate('privacyInfo.sub12sub2')}
                </Typography>
                <Typography align="justify" paragraph>
                  {translate(`privacyInfo.sub12sub3${countryCode}`)}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  )
}

export default PrivacyInfoPage
