import { Box, Button, Card, CardContent, Divider, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { FormLayout } from '..'
import { GOOGLE_TAG_MANAGER, ROUTES } from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import { useSetLocale, useTranslate } from '../../lib/translate'
import { fetchCountryDepartmentActions, fetchResendVerifyMailActions } from '../../redux/actions'
import { getBackendCountryDepartmentData, getBackendUserData } from '../../redux/selectors'
import { hideEmail } from './../../lib/hideData'

const useStyles = makeStyles(theme => ({
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bottomMargin: {
    marginBottom: theme.spacing(5),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContentSpacing: {
    padding: theme.spacing(4),
  },
}))

const EmailConfirmationSentPage = ({
  email,
  fetchCountryDepartment,
  departmentId,
  emspMail,
  resendMail,
  countryDepartment,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const queryParams = useQueryParams()
  const pushToDataLayer = useDataLayer()
  const setLocale = useSetLocale()

  const countryParam = queryParams.get('country')

  const impressumRoute = `${ROUTES.IMPRESSUM}${countryParam ? `?country=${countryParam}` : ''}`

  const hiddenEmail = hideEmail(email)

  const handleResendMail = () => {
    resendMail({ email })
  }

  useEffect(() => {
    if (countryDepartment?.countryCode) {
      setLocale(countryDepartment.countryCode.toLowerCase())
    }
  }, [countryDepartment, setLocale])

  useEffect(() => {
    pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.REGISTRATION_START })
  }, [pushToDataLayer])

  useEffect(() => {
    if (departmentId) {
      fetchCountryDepartment({ id: departmentId })
    }
  }, [fetchCountryDepartment, departmentId])

  return (
    <FormLayout>
      <Box className={classes.bottomMargin}>
        <Typography variant="h5" className={classes.textBold} gutterBottom>
          {translate('confirmMail.title')}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {translate('confirmMail.subtitle')}
        </Typography>
      </Box>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentSpacing}>
          <Typography variant="body1" paragraph>
            {translate('confirmMail.body1')}
            <Typography variant="body1" component="span" className={classes.textBold}>
              {hiddenEmail}
            </Typography>
            {translate('confirmMail.body2')}
          </Typography>
          <Typography variant="body2" paragraph>
            {translate('confirmMail.resend1')}
            <Link color="secondary" onClick={handleResendMail}>
              {` ${translate('confirmMail.link')} `}
            </Link>
            {`${translate('confirmMail.resend2')} ${
              emspMail ?? translate('confirmMail.mailshardcodedplsdelete')
            } ${translate('confirmMail.resend3')}`}
          </Typography>
          <Typography variant="caption" color="textSecondary" paragraph>
            {translate('confirmMail.info')}
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.bottomMargin}>
        <Button color="primary" component={RouterLink} to={ROUTES.LOGIN}>
          {translate('finishRegistration.backToLogin')}
        </Button>
      </Box>
      <Box>
        <Divider variant="middle" />
        <Box mt={2} ml={3}>
          <Link color="textSecondary" href={impressumRoute} target="__blank">
            {translate('drawer.drawerLink.legalInfo')}
          </Link>
        </Box>
      </Box>
    </FormLayout>
  )
}

EmailConfirmationSentPage.propTypes = {
  email: PropTypes.string,
  resendMail: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  email: get(getBackendUserData(state), 'email'),
  departmentId: get(getBackendUserData(state), 'countryDepartment'),
  emspMail: getBackendCountryDepartmentData(state)?.emspEmail,
  countryDepartment: getBackendCountryDepartmentData(state),
})

const mapDispatchToProps = {
  resendMail: fetchResendVerifyMailActions.requestAction,
  fetchCountryDepartment: fetchCountryDepartmentActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationSentPage)
