import React from 'react'
import { Box, makeStyles, Typography, Container, Paper, CardMedia } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import { COUNTRY_DEPARTMENT } from '../../config/constants'
import useAppLogo from '../../lib/customHooks/useAppLogo'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  img: {
    maxHeight: '4rem',
    width: 'unset',
    objectFit: 'contain',
  },
}))

const ImpressumPage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const appLogo = useAppLogo()

  const countryParam = queryParams.get('country')

  const countryCode =
    countryParam &&
    Object.values(COUNTRY_DEPARTMENT).some(it => it.tld === countryParam.toLowerCase())
      ? countryParam.toUpperCase()
      : 'AT'

  return (
    <Container maxWidth="md">
      <Paper>
        <Box p={4} minHeight="100vh">
          <Box my={3}>
            <CardMedia className={classes.img} component="img" alt="App Logo" image={appLogo} />
          </Box>
          <Typography align="center" variant="h4" paragraph>
            {translate(`impressum.title${countryCode}`)}
          </Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub1${countryCode}`)}
          </Typography>
          <Typography>{translate(`impressum.sub1sub1${countryCode}`)}</Typography>
          <Typography paragraph>{translate(`impressum.sub1sub2${countryCode}`)}</Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub2`)}
          </Typography>
          <Typography paragraph>{translate(`impressum.sub2sub1${countryCode}`)}</Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub3`)}
          </Typography>
          <Typography>{translate(`impressum.sub3sub1${countryCode}`)}</Typography>
          {['AT', 'DE'].includes(countryCode) && (
            <Typography>{translate(`impressum.sub3sub2${countryCode}`)}</Typography>
          )}
          <Typography paragraph>{translate(`impressum.sub3sub3${countryCode}`)}</Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub4`)}
          </Typography>
          {['AT', 'DE'].includes(countryCode) && (
            <Typography>{translate(`impressum.sub4sub1${countryCode}`)}</Typography>
          )}
          <Typography paragraph>{translate(`impressum.sub4sub2${countryCode}`)}</Typography>
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub5${countryCode}`)}
          </Typography>
          <Typography align="justify" paragraph>
            {translate(`impressum.sub5sub1${countryCode}`)}
          </Typography>
          {['AT', 'DE'].includes(countryCode) && (
            <>
              <Typography align="justify" variant="h6" className={classes.bold}>
                {translate(`impressum.sub6`)}
              </Typography>
              <Typography align="justify" paragraph>
                {translate(`impressum.sub6sub1`)}
              </Typography>
            </>
          )}
          {countryCode === 'AT' && (
            <>
              <Typography variant="h6" className={classes.bold}>
                {translate(`impressum.sub7AT`)}
              </Typography>
              <Typography>{translate(`impressum.sub7sub1AT`)}</Typography>
              <Typography>{translate(`impressum.sub7sub2AT`)}</Typography>
              <Typography paragraph>{translate(`impressum.sub7sub3AT`)}</Typography>
            </>
          )}
          <Typography variant="h6" className={classes.bold}>
            {translate(`impressum.sub8`)}
          </Typography>
          <Typography align="justify">{translate(`impressum.sub8sub1${countryCode}`)}</Typography>
        </Box>
      </Paper>
    </Container>
  )
}

export default ImpressumPage
