import { METAL_TYPE, REQUEST_RECORD_TYPE_MAPPING, SHIPPING_PAYMENT_MAPPING } from '../config'

/**
 * TRANSFORM PLAN DATA FOR USE IN DASHBOARD, IF THE SALESFORCE FIELDS ARE FINALIZED, ADJUST THE SAVINGSPLANCARD AND REMOVE THE TRANSFORM FUNCTIONS
 */
export const transformPlanDataForDashboardCard = ({
  currentValue,
  totalPerformance,
  metalName,
  ...rest
}) => ({
  ...rest,
  value: currentValue,
  valueChange: totalPerformance,
  metal: metalName.toLowerCase(),
})

export const transformDataForRequest = ({
  goldMonthly,
  silberMonthly,
  platinMonthly,
  palladiumMonthly,
  goldStartMonth,
  silberStartMonth,
  platinStartMonth,
  palladiumStartMonth,
  gold,
  silber,
  platin,
  palladium,
  subscribeNewsletter,
  ...rest
}) => ({
  goldMonthly: goldMonthly,
  silverMonthly: silberMonthly,
  platinumMonthly: platinMonthly,
  palladiumMonthly: palladiumMonthly,
  goldStartMonth: goldStartMonth ? goldStartMonth.value : undefined,
  silverStartMonth: silberStartMonth ? silberStartMonth.value : undefined,
  platinumStartMonth: platinStartMonth ? platinStartMonth.value : undefined,
  palladiumStartMonth: palladiumStartMonth ? palladiumStartMonth.value : undefined,
  total:
    (+goldMonthly || 0) + (+silberMonthly || 0) + (+platinMonthly || 0) + (+palladiumMonthly || 0),
  gold: gold ?? false,
  silver: silber ?? false,
  platinum: platin ?? false,
  palladium: palladium ?? false,
  subscribeNewsletter: subscribeNewsletter ?? false,
  ...rest,
})

export const transformPayoutCancelRequest = ({
  amount,
  physicalDelivery,
  shippingPayment,
  ...rest
}) => ({
  amount: amount ? parseFloat(amount) : undefined,
  physicalDelivery: physicalDelivery ?? false,
  payForFollowUpCosts: shippingPayment === SHIPPING_PAYMENT_MAPPING.STORE.key ? true : undefined,
  shippingPayment,
  ...rest,
})

export const transformRequestForTable = ({
  recordTypeId,
  isGoldPlan,
  isSilverPlan,
  isPalladiumPlan,
  isPlatinumPlan,
  total,
  ...rest
}) => {
  const variety = Object.entries({
    [`metals.${METAL_TYPE.GOLD}`]: isGoldPlan,
    [`metals.${METAL_TYPE.SILVER}`]: isSilverPlan,
    [`metals.${METAL_TYPE.PALLADIUM}`]: isPalladiumPlan,
    [`metals.${METAL_TYPE.PLATINUM}`]: isPlatinumPlan,
    // eslint-disable-next-line no-sequences
  }).reduce((arr, [k, v]) => (v && arr.push(k), arr), [])

  return {
    type: REQUEST_RECORD_TYPE_MAPPING[recordTypeId],
    recordTypeId,
    variety,
    rate: total,
    ...rest,
  }
}
